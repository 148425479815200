.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block { float: left; width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between; background: #fff; padding: 10px 15px; border-radius: 5px; height: 420px; overflow: hidden; -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); border-radius: 5px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-txt { background: #5d5d5d; color: #fff; padding: 0 10px; line-height: 18px; font-size: 11px; border-radius: 5px; height: 20px; float: left; margin-bottom: 10px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card { display: flex; width: 100%; border: none; outline: none; box-shadow: none; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body { display: flex; width: 100%; border: none; outline: none; box-shadow: none; flex-wrap: wrap; justify-content: space-between; padding: 0; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block { display: inline-block; width: 47%; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img { float: left; width: 100%; border-radius: 5px; overflow: hidden; position: relative; height: 134px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img .latest-name { position: absolute; left: 0; bottom: 0; width: 100%; background: rgba(0, 0, 0, 0.6); padding: 5px 15px; color: #fff; font-size: 13px; line-height: normal; text-align: center; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img .latest-img { float: left; width: 100%; border-radius: 5px; overflow: hidden; height: 100%; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img .latest-img img { width: 100%; height: 100%; object-fit: cover; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt { display: inline-block; width: 100%; padding-top: 7px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-top { display: flex; width: 100%; flex-wrap: wrap; align-items: center; justify-content: space-between; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-top .list-top-lft { display: inline-block; width: 50%; text-align: left; font-size: 11px; line-height: normal; padding-left: 13px; background: url("../../images/doticon-blue.svg") no-repeat 0 center; background-size: 8px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-top .list-top-rgt { display: inline-block; font-size: 11px; line-height: normal; text-align: right; width: auto; max-width: 50%; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt h2 { float: left; width: 100%; font-size: 18px; line-height: normal; color: #000; max-height: 170px; overflow: hidden; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt h4 { float: left; width: 100%; font-size: 14px; line-height: normal; color: #969696; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom { display: flex; flex-wrap: wrap; width: 100%; justify-content: space-between; align-items: center; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom .list-btm-lft { display: flex; width: 55%; align-items: center; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom .list-btm-lft .like { display: inline-block; color: #434343; font-size: 12px; padding-left: 18px; background: url("../../images/like-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; margin-right: 15px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom .list-btm-lft .viewcount { display: inline-block; color: #434343; font-size: 12px; padding-left: 18px; background: url("../../images/eye-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; margin-right: 15px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom .list-btm-lft .comment { display: inline-block; color: #434343; font-size: 12px; padding-left: 18px; background: url("../../images/comment-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; margin-right: 15px; }
.row.row-to .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom .list-btm-lft .dislike { display: inline-block; color: #434343; font-size: 12px; padding-left: 18px; background: url("../../images/dislike-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom .list-btm-rgt { display: flex; width: 40%; align-items: center; }
.row.row-two .col-md-9 .row.split-row.row-three { margin-bottom: 15px; margin-left: 0; margin-right: -7px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 { padding-left: 0; padding-right: 0; width: 100%; }
.row.row-two .col-md-9 .row.split-row .col-md-12.block-col2 { width: 100%; }
.row.row-two .col-md-9 .row.split-row.row-one  .col-md-12.block-col2 { padding: 0 5px 0 0; background: none; }
.row.row-two .col-md-9 .row.split-row .col-md-12.block-col2 .latest-wrap.latest-wrap-row { background: #fff; padding: 10px 15px; border-radius: 5px; height: 420px; overflow: hidden; display: inline-block; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col2 { padding-left: 0; width: 100%; padding-right: 0; -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); border-radius: 5px; }

.row.row-two .col-md-9 .row .col-md-6:nth-child(2n + 2) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card, .row.row-two .col-md-9 .row .col-md-6:nth-child(4) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card, .row.row-two .col-md-9 .row .col-md-6:nth-child(6) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card { height: 90%; }

.row.row-two .col-md-9 .row .col-md-6:nth-child(2n + 2) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body, .row.row-two .col-md-9 .row .col-md-6:nth-child(4) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body, .row.row-two .col-md-9 .row .col-md-6:nth-child(6) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body { height: 100%; display: inline-block!important; width: 100%; }

.row.row-two .col-md-9 .row .col-md-6:nth-child(2n + 2) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block, .row.row-two .col-md-9 .row .col-md-6:nth-child(4) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block, .row.row-two .col-md-9 .row .col-md-6:nth-child(6) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block { width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap; height: auto; max-height: 150px; }

.row.row-two .col-md-9 .row .col-md-6:nth-child(2n + 2) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img, .row.row-two .col-md-9 .row .col-md-6:nth-child(4) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img, .row.row-two .col-md-9 .row .col-md-6:nth-child(6) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img { width: 45%; }

.row.row-two .col-md-9 .row .col-md-6:nth-child(2n + 2) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt, .row.row-two .col-md-9 .row .col-md-6:nth-child(4) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt, .row.row-two .col-md-9 .row .col-md-6:nth-child(6) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt { width: 50%; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2.no-slide .slick-track { transform: translate3d(0, 0, 0)!important; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .content { display: none; }
.latest-wrap-row { width: 100%!important; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right a { float: left; width: 100%; }
.latest-wrap-row .content { overflow: hidden; max-height: 40px; float: left; width: 100%; color: #969696; font-size: 13px; margin-bottom: 10px; }
.news-wrap-block .content { text-overflow: ellipsis; overflow: hidden; height: auto; }
.row.split-row .slick-slider.news-slider { float: left; width: 100%; height: 312px; }
.row-one .block-col1 .slick-slider.news-slider .news-block { border-radius: 0 0 5px 5px!important; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .content { display: none; }

.row.row-two .col-md-9 .row .col-md-6:nth-child(2n + 2) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block a {display: flex; flex-wrap: wrap; justify-content: space-between; width: 100%; text-decoration: none;}

@media (max-width:991px) {
  .row-one .block-col1 .news-block .card-body .trend-news .hd { font-size: 10px; }
  .row.row-two .block-col2 .latest-wrap .latest-txt { font-size: 10px; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-txt { font-size: 10px; }
}

@media screen and (min-width:768px) and (max-width:991px) {
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-top .list-top-rgt { font-size: 9px; max-width: 100%; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt h2 { font-size: 13px; max-height: 29px; line-height: 14px; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img .latest-name { font-size: 10px; max-height: 40px; line-height: normal; padding: 5px 10px 10px 10px; }
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-top .list-top-rgt { font-size: 10px; max-width: 55%; }
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right h2 { font-size: 13px; max-height: inherit; line-height: 14px; }
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .content p { font-size: 10px; }
  .row.row-two .col-md-9 .row .col-md-6:nth-child(2n + 2) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img,   .row.row-two .col-md-9 .row .col-md-6:nth-child(4) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img, .row.row-two .col-md-9 .row .col-md-6:nth-child(6) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img { height: 100px; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img { height: 100px; }
  .row.row-two .col-md-9 .row.split-row .col-md-12.block-col2 .latest-wrap.latest-wrap-row { height: 320px; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block { height: 320px; }
}

@media (max-width:767px) {
  .row.row-two .col-md-9 .row.split-row.row-three { margin-left: 0; margin-right: 0; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt  .content { float: left; width: 100%; display: none; }
  .row.row-two .col-md-9 .row.split-row .col-md-12.block-col2 .latest-wrap.latest-wrap-row { height: auto; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block { height: auto; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img {
    height: 230px;
}

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-left {
    height: 230px;
}

.row.row-two .col-md-9 .row .col-md-6:nth-child(2n + 2) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block {
  max-height: inherit;
}

}
@media (max-width:560px) {
.row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img {
  height: 200px;
}

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-left {
  height: 200px;
}
}
@media (max-width:480px) {
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-top .list-top-rgt { font-size: 9px; }
  .row-one .block-col1 .news-block .card-body .trend-news .hd { font-size: 10px; padding: 0 5px; }
  .row-one .block-col1 .news-block .card-body .trend-news .marquee .trend-txt { font-size: 14px; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img {
    height: 180px;
  }
  
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-left {
    height: 180px;
  }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block { width: 100%; }
}
@media (max-width:414px) {

}
@media (max-width:400px) {
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body { flex-direction: column; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block { width: 100%; }
  .news-dtl-wrap .news-dtl-head .newsdtl-ifo { margin-bottom: 10px; }
  .news-dtl-wrap .news-dtl-head h1 { margin: 0 0 10px 0; }
  .row.row-two .col-md-9 .row.split-row .col-md-12.block-col2 .latest-wrap.latest-wrap-row { height: auto; }
  .row.row-two .split-row.row-one .block-col2 .latest-wrap { max-height: inherit; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt  .content { float: left; width: 100%; display: none; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img {
    height: 150px;
  }
  
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-left {
    height: 150px;
  }

  .row.row-two .col-md-9 .row .col-md-6:nth-child(2n + 2) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt {
    width: 100%;
    margin-top: 15px;
}

.row.row-two .col-md-9 .row .col-md-6:nth-child(2n + 2) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img {
    width: 100%;
    max-height: 150px;
}

}


