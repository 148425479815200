@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Righteous&display=swap);

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
body {
  /* font-family: "Poppins", sans-serif !important; */
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.App {
  text-align: center;
}

img {
  max-width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.container {
  width: 1360px !important;
  max-width: 94% !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #3686f7 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #3686f7;
  border-radius: 5px;
  border: 1px solid #fff;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  background: #f3f3f8 !important;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1630px;
  max-width: 94%;
  margin: 0 auto;
}

/*header  */

/*footer  */
.footer-wrap {
  float: left;
  width: 100%;
  background: #222;
  margin: 30px 0 0 0;
  padding: 40px 0 0 0;
  position: relative;
  z-index: 99;
}

.footer-wrap .row.copyright {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.footer-wrap .container {
  display: block;
  margin: 0 auto;
}

.footer-wrap .row.ftr-nav nav.bg-body-tertiary.navbar.navbar-expand-lg {
  background: none !important;
  justify-content: left;
  padding: 10px 0;
  float: left;
  width: 100%;
  text-align: left;
}

.footer-wrap .row.ftr-nav nav.bg-body-tertiary.navbar.navbar-expand-lg a.nav-link {
  background: #303030;
  margin: 0 10px 0 0;
  width: 36px;
  height: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.footer-wrap .row.ftr-nav nav.bg-body-tertiary.navbar.navbar-expand-lg a.nav-link:hover {
  background: #000;
}

.footer-wrap .row.copyright p {
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
}

.footer-wrap .row.copyright p a {
  text-decoration: none;
  color: #3686f7;
  transition: ease all 0.5s;
}

.footer-wrap .row.copyright p a:hover {
  color: #fff;
}

.footer-wrap .row.ftr-nav .ftr-col-txt {
  float: left;
  width: 100%;
  margin: 0 0 15px 0;
}

.footer-wrap .row.ftr-nav .ftr-col-txt p {
  float: left;
  width: 100%;
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 26px;
}

.footer-wrap .row.ftr-nav .ftr-col-txt p:nth-child(2) {
  margin-bottom: 15px;
}

.footer-wrap .row.ftr-nav .more-news .more-post {
  float: left;
  width: 100%;
}

.footer-wrap .row.ftr-nav .more-news .more-post .latest-txt {
  background: #fff;
  color: #000;
  padding: 0 10px;
  line-height: 18px;
  font-size: 11px;
  border-radius: 5px;
  height: 20px;
  float: left;
  margin-bottom: 20px;
}

.footer-wrap .row.ftr-nav .more-news .more-post .more-block.card {
  display: flex;
  width: 100%;
  box-shadow: none;
  background: none !important;
  outline: none !important;
  border: none !important;
}

.footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body {
  height: 100%;
  display: inline-block !important;
  width: 100%;
  box-shadow: none;
  padding: 0;
  background: none !important;
  outline: none !important;
  border: none !important;
}

.footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;
  max-height: 150px;
}

.footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box .more-img {
  float: left;
  width: 120px;
  max-width: 30%;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  height: 80px;
}

.footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box .more-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box a {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box .more-txt {
  display: inline-block;
  width: calc(100% - 145px);
  float: left;
  color: #fff;
  margin-bottom: 0;
}

.footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box .more-txt h2 {
  width: 100%;
  float: left;
  color: #fff;
  margin-bottom: 5px;
  font-size: 15px;
  line-height: 22px;
}

.footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box .more-txt p {
  width: 100%;
  float: left;
  color: #fff;
  margin-bottom: 0;
  font-size: 10px;
  line-height: normal;
}

.footer-wrap .copyright-wrap {
  float: left;
  width: 100%;
  background: #000;
  margin-top: 15px;
}

.footer-wrap .row.ftr-nav .popular-category .popular-txt {
  background: #fff;
  color: #000;
  padding: 0 10px;
  line-height: 18px;
  font-size: 11px;
  border-radius: 5px;
  height: 20px;
  float: left;
  margin-bottom: 20px;
}

.footer-wrap .row.ftr-nav .popular-category ul {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
}

.footer-wrap .row.ftr-nav .popular-category ul li {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.footer-wrap .row.ftr-nav .popular-category ul li a {
  float: left;
  width: 100%;
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
  line-height: normal;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-wrap .row.ftr-nav .popular-category ul li a .popular-content {
  display: inline-block;
}

.footer-wrap .row.ftr-nav .popular-category ul li a .popular-no {
  display: inline-block;
  transition: ease all 0.5s;
}

.footer-wrap .row.ftr-nav .popular-category ul li a:hover {
  color: #3686f7;
}

.footer-wrap .row.ftr-nav .popular-category ul li:last-child {
  margin-bottom: 0;
}

.footer-wrap .row.ftr-nav .col-md-5.more-news {
  padding-right: 2%;
  width: 38%;
}

.footer-wrap .row.ftr-nav .col-md-3.social-ul {
  width: 38%;
  padding-right: 2%;
}

.footer-wrap .row.ftr-nav .col-md-4.popular-category {
  width: 24%;
}

.sc-dSfdvi.lQfFq {
  width: 250px !important;
  height: auto !important;
}

.row.paginate-row ul {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.row.paginate-row ul .previous a {
  width: auto;
  padding: 0 10px;
}

.row.paginate-row ul li.selected a {
  background: #3686f7;
  border: 1px solid transparent;
  color: #fff;
}

.row.paginate-row ul li a {
  display: inline-block;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  border: 1px solid #dfdfe3;
  color: #333;
  font-size: 15px;
  transition: ease all 0.5s;
  text-decoration: none;
}

.row.paginate-row ul li.next a {
  width: auto;
  padding: 0 10px;
}

.row.paginate-row ul li {
  display: inline-block;
  margin: 0 2px 7px 2px;
}

.row.paginate-row ul li a:hover {
  background: #3686f7;
  border: 1px solid transparent;
  color: #fff;
}

.footer-wrap .row.ftr-nav .subscribe-wrap {
  float: left;
  width: 100%;
  margin-top: 20px;
  background: #2e2b2b;
  padding: 30px;
  border-radius: 5px;
}

.footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-head {
  float: left;
  width: 100%;
}

.footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-head h2 {
  color: #fff;
  font-size: 22px;
  font-weight: normal;
}

.footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-head p {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body {
  float: left;
  width: 100%;
  margin: 15px 0 0 0;
}

.footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body form {
  float: left;
  width: 90%;
}

.footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body form .input-field {
  float: left;
  width: 100%;
  margin: 0 0 15px 0;
}

.footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body form .input-field input.form-control {
  background: #222;
  height: 45px;
  color: #fff;
  font-size: 14px;
  padding: 0 15px;
  border-radius: 6px;
}

.footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body form .input-field input.form-control::placeholder {
  color: #fff;
}

.footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body form .input-field .mb-3 {
  margin: 0 !important;
}

.footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body form .input-field .mb-3 label.form-label {
  display: none;
}

.footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body form button.btn.btn-primary {
  background: #3686f7;
  height: 45px;
  color: #fff;
  font-size: 15px;
  padding: 0 25px;
  border: 1px solid #3686f7;
  transition: ease all 0.5s;
  border-radius: 5px;
}

.footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body form button.btn.btn-primary:hover {
  background: #fff;
  height: 45px;
  color: #3686f7;
  border: 1px solid #fff;
}

.newsdtl-tags {
  float: left;
  width: 100%;
  margin: 0 0 20px 0;
}

.newsdtl-tags span.tag.m-2 {
  display: inline-block;
  font-weight: 600;
}

[dir="rtl"] .footer-wrap .row.ftr-nav nav.bg-body-tertiary.navbar.navbar-expand-lg {
  justify-content: right;
}

[dir="rtl"] .footer-wrap .row.ftr-nav nav.bg-body-tertiary.navbar.navbar-expand-lg a.nav-link {
  margin: 0 0 0 10px;
}

[dir="rtl"] .footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body form {
  float: right;
}

[dir="rtl"] .footer-wrap .row.ftr-nav .more-news .more-post .latest-txt {
  float: right;
}

[dir="rtl"] .footer-wrap .row.ftr-nav .popular-category .popular-txt {
  float: right;
}

@media (max-width:1280px) {
  .footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box .more-txt {
    width: calc(100% - 145px);
  }

  .footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box .more-img {
    max-width: 125px;
  }
}

@media (max-width:991px) {
  .container {
    width: 100% !important;
    max-width: 100% !important;
  }

  .footer-wrap .row.ftr-nav nav.bg-body-tertiary.navbar.navbar-expand-lg .navbar-nav {
    display: flex;
    flex-direction: row;
  }

  .footer-wrap .row.ftr-nav nav.bg-body-tertiary.navbar.navbar-expand-lg a.nav-link {
    width: 32px;
    height: 32px;
  }

  .footer-wrap .row.ftr-nav nav.bg-body-tertiary.navbar.navbar-expand-lg {
    padding: 5px 0;
  }

  .footer-wrap .row.copyright {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .footer-wrap .row.ftr-nav .col-md-5.more-news {
    padding-left: 5%;
  }

  .footer-wrap .row.ftr-nav .ftr-col-txt p {
    font-size: 11px;
    line-height: 20px;
  }

  .footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box .more-txt h2 {
    font-size: 12px;
    line-height: 20px;
  }

  .footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box .more-img {
    width: 105px;
    max-width: 27%;
    height: 65px;
  }

  .footer-wrap .row.copyright p {
    font-size: 11px;
  }

  .recent-post .recent-block.card .card-body .recent-img {
    height: 100px;
  }

  .recent-post .recent-block.card .card-body span {
    font-size: 11px;
    padding: 5px 10px 10px 10px;
  }

  .footer-wrap .row.ftr-nav .popular-category ul li a .popular-content {
    font-size: 11px;
  }

  .footer-wrap .row.ftr-nav .popular-category ul li a .popular-no {
    font-size: 11px;
  }

  .footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box .more-txt {
    width: calc(100% - 90px);
  }
}

@media screen and (min-width:768px) and (max-width:991px) {
  .recent-post .recent-block.card .card-body span {
    font-size: 9px;
    padding: 5px 10px 10px 10px;
    max-height: 74%;
  }
}

@media (max-width:767px) {
  .footer-wrap .row.copyright p {
    font-size: 12px;
  }

  .footer-wrap .row.ftr-nav .ftr-col-txt {
    text-align: center;
  }

  .footer-wrap .row.ftr-nav nav.bg-body-tertiary.navbar.navbar-expand-lg {
    text-align: center;
    justify-content: center;
  }

  .footer-wrap .row.ftr-nav .col-md-5.more-news {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .recent-post .recent-block.card .card-body .recent-img {
    height: 220px;
  }

  .footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box .more-txt {
    width: calc(100% - 100px);
  }

  .footer-wrap .row.ftr-nav .col-md-3.social-ul {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .footer-wrap .row.ftr-nav .col-md-5.more-news {
    width: 100%;
  }

  .footer-wrap .row.ftr-nav .col-md-4.popular-category {
    width: 100%;
  }

  .row.paginate-row ul li a {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    width: 32px;
  }

  .footer-wrap .row.ftr-nav .more-news .more-post .more-block.card .card-body .more-box .more-img {
    width: 85px;
    max-width: 85px;
    height: 65px;
  }

  .footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body form .input-field input.form-control {
    height: 40px;
  }

  .footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body form {
    width: 100%;
    text-align: center;
  }

  .footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-head h2 {
    text-align: center;
  }

  .footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-head p {
    text-align: center;
  }

  .footer-wrap .row.ftr-nav .subscribe-wrap .subscribe-body form button.btn.btn-primary {
    float: none;
    margin: 0 auto;
  }

  .footer-wrap .row.ftr-nav .subscribe-wrap {
    padding: 15px;
  }
}

@media (max-width:414px) {
  .footer-wrap .row.copyright p {
    font-size: 11px;
  }

  .recent-post .recent-block.card .card-body .recent-img {
    height: 140px;
  }

  .footer-wrap {
    margin: 20px 0 0 0;
    padding: 30px 0 0 0;
  }
}