.about-wrap {
    float: left;
    width: 101%;
    padding: 30px;
    padding-right: 5px;
    background: #fff;
    border-radius: 5px;
}
 

.about-wrap .about-row .about-col p {
    color: #000;
    float: left;
    width: 100%;
    font-size: 16px;
    margin: 0 0 20px 0;
}
.about-wrap .about-row .about-col p:last-child {
    margin-bottom: 0;
}
@media (max-width:991px) {
 
}

@media (max-width:767px) {
.about-wrap .about-row .about-col p {
    font-size: 15px;
}
.about-wrap {
    padding: 20px;
    width: 100%;
}
}