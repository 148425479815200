.product-slider .slick-track .slick-slide > div { display: inline-block; }
.product-slider .slick-track .slick-slide .brand-list {    border-radius: 5px; margin: 0 6%; width: 171px!important; max-width: 94%!important; position: relative; overflow: hidden; height: 80px; }
.product-slider .slick-track .slick-slide .brand-list .brand-name { background: rgba(0, 0, 0, 0.6); display: inline-block; width: 100%; position: absolute; left: 0; bottom: 0; text-align: center; color: #fff; font-size: 18px; padding: 2px 2px; line-height: normal; }
.product-slider .slick-track .slick-slide .brand-list img {     border-radius: 5px; width: 100%; height: 100%; object-fit: cover; display: inline-block; }
.product-slider button.slick-arrow.slick-next { width: 32px; height: 32px; background: url("../../images/slider-right-arrow.svg") no-repeat center center; background-size: 32px; right: -40px; }
.product-slider button.slick-arrow.slick-next::before { display: none; }
.product-slider button.slick-arrow.slick-prev { width: 32px; height: 32px; background: url("../../images/slider-left-arrow.svg") no-repeat center center; background-size: 32px; left: -40px; }
.product-slider button.slick-arrow.slick-prev::before { display: none; }
.slick-slider.product-slider.no-slide button.slick-arrow.slick-prev { display: none!important; }
.slick-slider.product-slider.no-slide button.slick-arrow.slick-next { display: none!important; }

.body-container .brand-wrp .slider-wrap .slick-slider.product-slider.no-slide .slick-list .slick-track { transform: translate3d(0, 0, 0)!important; display: flex; justify-content: center; }
.brand-wrp .slider-wrap.slide-desk .slick-slide>div { max-width: 100%; }
.brand-wrp .slider-wrap.slide-desk .slick-slide > div a { max-width: 100%; }
@media (max-width:991px) {
  .product-slider .slick-track .slick-slide .brand-list { width: 145px!important; max-width: 88%!important; height: 60px; }
}
@media screen and (min-width:768px) and (max-width:991px) {
  .product-slider .slick-track .slick-slide .brand-list .brand-name { font-size: 15px; line-height: 20px; }
}
@media (min-width:768px) {
  .body-container .brand-wrp .slider-wrap.slide-mob { display: none; }
}
@media (max-width:767px) {
  .body-container .brand-wrp .slider-wrap .slick-slider.product-slider.no-slide.no-slide-4 .slick-list .slick-track { transform: inherit!important; display: block!important; justify-content: inherit!important; }
  .slick-slider.product-slider.no-slide.no-slide-4 button.slick-arrow.slick-prev { display: inherit!important; left: 0!important; z-index: 99!important; }
  .slick-slider.product-slider.no-slide.no-slide-4 button.slick-arrow.slick-next { display: inherit!important; right: 0!important; z-index: 99!important; }
  .body-container .brand-wrp .slider-wrap.slide-desk { display: none; }
  .body-container .brand-wrp .slider-wrap.slide-mob button.slick-arrow.slick-prev { left: -5%!important; z-index: 99!important; width: 28px; height: 28px; background-size: 28px; }
  .body-container .brand-wrp .slider-wrap.slide-mob button.slick-arrow.slick-next { right: -5%!important; z-index: 99!important; width: 28px; height: 28px; background-size: 28px; }
  .body-container .brand-wrp .slider-wrap.slide-mob { width: 90%; float: none; margin: 0 auto; }
  .body-container .brand-wrp .slider-wrap.slide-mob .slick-slide { text-align: center; }
  .product-slider .slick-track .slick-slide .brand-list { width: 130px!important; max-width: 88%!important; height: 60px; }
  .product-slider .slick-track .slick-slide .brand-list .brand-name { font-size: 15px; line-height: 20px; }
}

@media (max-width:600px) {
}
@media (max-width:480px) {
  .body-container .brand-wrp .slider-wrap.slide-mob { width: 86%; }
  .body-container .brand-wrp .slider-wrap.slide-mob button.slick-arrow.slick-next { right: -7%!important; }
  .body-container .brand-wrp .slider-wrap.slide-mob button.slick-arrow.slick-prev { left: -7%!important; }
}
@media (max-width:400px) {
  .body-container .brand-wrp .slider-wrap.slide-mob button.slick-arrow.slick-prev { left: -9%!important; width: 24px; height: 24px; background-size: 24px; }
  .body-container .brand-wrp .slider-wrap.slide-mob button.slick-arrow.slick-next { right: -9%!important; width: 24px; height: 24px; background-size: 24px; }
}
