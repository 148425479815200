.nav-menu.navbar-nav { display: flex; flex-wrap: wrap; align-items: center; justify-content: center; background: #fff; padding: 0; border-radius: 5px; height: 45px; transition: ease all0 .5s; }
.basic-navbar-nav { width: 100%; justify-content: center; }
nav.bg-body-tertiary.navbar { padding: 0; background: #fff!important; border-radius: 5px; }
.nav-menu.navbar-nav a.nav-link { display: inline-block; font-size: 16px; color: #000; text-decoration: none; padding: 0 12px; transition: ease all 0.5s; margin: 0 5px; }
.nav-menu.navbar-nav a.nav-link.active, .nav-menu.navbar-nav a.nav-link:hover { color: #3686f7; }
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav .navbar-slider-outer { overflow: hidden; padding-right: 60px; justify-content: left; width: 100%; }
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link { height: 45px; line-height: 45px; transition: ease all 0.5s; }

.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link.more-link { position: absolute; right: 5px; top: 0; margin: 0; height: 45px; width: 50px; background: url(../../images/dot-black.svg) no-repeat center center; background-size: 20px; font-size: 0!important; }

/* .body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav.showmore { overflow: visible; height: auto; padding-right: 0; } */

.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav.showmore a.nav-link.more-link { background: url(../../images/dot-black.svg) no-repeat center center; background-size: 20px; }
.body-container .main-menu #category-navbar-nav { display: inline-block!important; flex-basis: auto; width: 100%; }

.body-container .main-menu .navbar #category-navbar-nav .navbar-slider-outer { display: inline-block; width: 100%; position: relative; height: 45px; }

.body-container .main-menu .navbar #category-navbar-nav .navbar-slider-outer button.slick-arrow.slick-prev { display: none; }

.body-container .main-menu .navbar #category-navbar-nav .navbar-slider-outer button.slick-arrow.slick-next { width: auto; position: absolute; right: -60px; top: 23px; z-index: 99; }

.body-container .main-menu .navbar #category-navbar-nav .navbar-slider-outer button.slick-arrow.slick-next::before { content: "More"; font-size: 16px; color: #000; text-decoration: none; font-family: "Poppins",sans-serif!important; }
.body-container .main-menu .navbar #category-navbar-nav .navbar-slider-outer button.slick-arrow.slick-next:hover::before { content: "More"; color: #3686f7; }
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer { position: absolute; background: #fff; right: 0; width: 250px; z-index: 991; top: 45px; border-radius: 5px; box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3); opacity: 1; transition: ease all 0.5s; visibility: visible; padding: 10px 15px; }

.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link { float: left; width: 100%; margin: 0; height: 36px; line-height: 36px; }
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:hover { color: #3686f7; }
[dir="rtl"] .body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link.more-link { right: auto; left: 5px; }
[dir="rtl"]  .body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav .navbar-slider-outer { padding-left: 60px; padding-right: 0; }
[dir="rtl"] .body-container .main-menu #category-navbar-nav .navbar-dropdown-outer { right: auto; left: 0; }
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link:nth-child(7), 
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link:nth-child(8)   {
  display: none;
}

.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(1), 
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(2), 
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(3), 
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(4), 
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(5) {
  display: none;
}


@media (max-width:1500px) {
  .body-container .main-menu .navbar #category-navbar-nav .navbar-slider-outer { padding-right: 75px; }
  
  .body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link { font-size: 15px; }
}
@media (max-width:1400px) {
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link {
  font-size: 15px;
}
}

@media (max-width:1366px) {
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link {
  font-size: 15px;
  padding: 0 10px;
}
}
@media (max-width:1300px) {
  .nav-menu.navbar-nav a.nav-link { font-size: 14px; }
  .body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link { font-size: 14px; }
  .body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link:nth-child(6), 
  .body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link:nth-child(7), 
  .body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link:nth-child(8) {
    display: none;
}
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(2), 
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(3), 
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(4){
  display: none;
}
 
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(5){
  display: inline-block;
} 
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link {
  font-size: 15px;
  padding: 0 12px;
}
}


@media (max-width:1100px) {
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link {
  font-size: 16px;
  padding: 0 15px;
}
 
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link:nth-child(5), 
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link:nth-child(6), 
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link:nth-child(7), 
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link:nth-child(8) {
  display: none;
}
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(2), 
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(3), 
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(4){
display: none;
}
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(4) {
  display: inline-block;
}
}

@media (max-width:991px) {
  .body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link {         font-size: 15px;
    padding: 0 12px; }
  .body-container .main-menu .navbar #category-navbar-nav .navbar-slider-outer button.slick-arrow.slick-next::before { font-size: 15px; }
}

@media screen and (min-width:768px) and (max-width:991px) {
  .body-container .main-menu .navbar #category-navbar-nav .navbar-slider-outer button.slick-arrow.slick-next::before { font-size: 14px; }
}


@media (max-width:860px) {
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link {
  font-size: 14px;
  padding: 0 10px;
}
}
@media (max-width:767px) {
  .body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link { height: 40px; line-height: 40px; }
  .body-container .main-menu .navbar #category-navbar-nav .navbar-slider-outer button.slick-arrow.slick-next { top: 19px; right: -45px; }
  .body-container .main-menu .navbar #category-navbar-nav .navbar-slider-outer { width: 100%; }
  .body-container .main-menu .navbar #category-navbar-nav .navbar-slider-outer { padding-right: 50px; }
  .body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link { font-size: 15px; padding: 0 10px; }
  .body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link { font-size: 14px; }
}

@media (max-width:660px) {

.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link:nth-child(4){display: none;}
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(3) {
  display: inline-block;
}
}

@media (max-width:600px) {
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link {
  font-size: 14px;
  padding: 0 8px;
}
}
@media (max-width:520px) {
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link {
  font-size: 12px;
  padding: 0 4px;
}
}
@media (max-width:425px) {
  .body-container .main-menu .navbar #category-navbar-nav .navbar-slider-outer button.slick-arrow.slick-next { top: 19px; right: -60px; }
  .body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link {
    font-size: 13px;
    padding: 0 6px;
  }
  .body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link.more-link {
    width: 40px;
    background-size: 18px;
}
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link:nth-child(3) {
  display: none;
}
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link:nth-child(2){
  display: inline-block;
}
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link {
  font-size: 13px;
  line-height: 32px;
  height: 32px;
}
}
@media (max-width:375px) {
.body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav a.nav-link {
  font-size: 12px;
  padding: 0 6px;
}
.body-container .main-menu #category-navbar-nav .navbar-dropdown-outer a.nav-link {
  font-size: 12px;

}
}