.forgotWrapp {
    width: 100%;
    display: block;
    float: left;
    padding: 0 3% 40px;
}

.forgotWrapp .forgotContainer {
    width: 100%;
    margin: 0 auto 15px;
    justify-content: center;
}

.forgotWrapp .forgotContainer .col-md-4 {
    width: 100%;
    padding: 14px 4%;
    background: #f3f3f8;
    margin: 0 auto 0;
    border-radius: 8px;
    max-width: 400px;
    border: 1px solid #ddd;
}

.forgotWrapp .forgotContainer .col-md-4 .log-head {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: end;
    margin: 10px 0 10px;
}

.forgotWrapp h3 {
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 0;
}

.forgotWrapp .forgotContainer .col-md-4 .log-head img {
    width: 44%;
    display: block;
    float: right;
}

.forgotWrapp .forgotContainer .forgotForm {
    width: 100%;
    display: block;
    float: left;
}

.forgotWrapp .forgotForm .formfield {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    float: left;
    padding: 20px 0 0;
}

.forgotWrapp .forgotContainer .forgotForm label {
    font-size: 16px;
    display: block;
}

.forgotWrapp .forgotContainer .forgotForm input {
    width: 100%;
    height: 42px;
    display: block;
    float: left;
    padding: 0 9px 0;
    margin: 5px 0 0;
    border: none;
    background: #ffffff;
    font-size: 15px;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    box-shadow: none;
    outline: none;
}

.forgotWrapp .forgotForm .frgtpw {
    color: #888888;
    font-size: 15px;
    float: right;
    text-decoration: none;
    margin: 6px 0 0;
}

.forgotWrapp .forgotForm .frgtpw:hover {
    color: #3686f7;
}

.forgotWrapp .forgotContainer .forgotForm input.forgotBtn1 {
    width: 100%;
    height: inherit;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.85px;
    padding: 10px 20px;
    margin: 10px 0 0;
    background: #3686f7;
    border: 1px solid #3686f7;
    color: #ffffff;
    border-radius: 30px;
}

.forgotWrapp .forgotContainer .forgotForm input.forgotBtn2 {
    width: 120px;
    height: inherit;
    background: none;
    color: #3686f7;
    border: 1px solid #3686f7;
    border-radius: 30px;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.85px;
    padding: 10px 20px;
    margin: 10px 0 0;
}

.forgotWrapp .forgotForm .forgotFooter {
    width: 100%;
    display: block;
    float: left;
    margin: 30px 0 0;
    padding: 20px 0 0;
    border-top: 1px solid #ddd;
}

.forgotWrapp .forgotContainer .forgotForm input.forgotBtn1:hover {
    background: none;
    color: #3686f7;
    border-color: #3686f7;
}

.forgotWrapp .forgotContainer .forgotForm input.forgotBtn2:hover {
    background: #3686f7;
    color: #ffffff;
    border-color: #3686f7;
}

.forgotWrapp .forgotForm .forgotFooter p {
    color: #888888;
    font-size: 15px;
    margin: 0 0 15px;
}

.forgotWrapp .forgotForm .forgotFooter p a {
    display: inline-block;
    text-decoration: none;
    padding: 0 0 0 10px;
    color: #3686f7;
}

.forgotWrapp .forgotForm .forgotFooter p a:hover {
    color: #888888;
}

.forgotWrapp .forgotContainer .col-md-4 .forgotBtn1 {
    width: 100%;
    border-radius: 30px;
    background: #3686F7;
    border-color: #3686F7;
    padding: 12px 15px;
}

.forgotWrapp .forgotContainer .col-md-4 .forgotBtn1:hover {
    background: #222;
    border-color: #222;
}

@media (max-width:414px) {

    .forgotWrapp .forgotContainer .forgotForm input.forgotBtn1, 
    .forgotWrapp .forgotContainer .forgotForm input.forgotBtn2 {width: 48%;}

    .forgotWrapp .forgotForm .forgotFooter p a {padding: 0;}

}