.cart-wrap {
    float: left;
    width: 101%;
    padding: 30px;
    padding-right: 5px; 
    border-radius: 5px;
}
 

.cart-wrap .cart-row .cart-col p {
    color: #000;
    float: left;
    width: 100%;
    font-size: 16px;
    margin: 0 0 20px 0;
}
.cart-wrap .cart-row .cart-col p:last-child {
    margin-bottom: 0;
}
.cart-item .cart-content{
    display: flex;
}
.cart-item .cart-content .cart-avatar{
    width: 50px;
}

.cart-wrap .col-md-4 .card .customer-login {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
}

.cart-wrap .col-md-4 .card .customer-login .input-field input {
    margin: 0;
}
.customer-login h2{margin-bottom: 16px;}
.cart-wrap .col-md-4 .card .customer-login .loginForm {
    padding: 0 2.7rem 0;
    padding-bottom: 2rem;
}

.cart-wrap .col-md-4 .card .customer-login .loginForm button.loginBtn1 {
    margin: 15px 0 12px;
    width: 100%;
}

.cart-wrap .col-md-4 .card .customer-login .loginForm p {
    margin: 0 0 1rem;
}

.cart-wrap .col-md-4 .card .customer-login .alert-dismissible .btn-close {width: 26px;height: 26px;top: -15px;padding: 0;}

.cart-wrap .col-md-4 .card .customer-login .alert-danger.alert-dismissible {
    margin: 1rem 2.2rem 1rem;
    padding: 14px 20px;
}

@media (max-width:991px) {
 
}

@media (max-width:767px) {
.cart-wrap .cart-row .cart-col p {
    font-size: 15px;
}
.cart-wrap {
    padding: 20px;
    width: 100%;
}
}