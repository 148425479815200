.news-dtl-wrap .news-dtl-head { float: left; width: 100%; position: relative; }

.news-dtl-wrap .news-dtl-head h4 { font-size: 13px; color: #000; padding-left: 25px; float: left; width: 100%; background: url(../images/red-box-icon.svg) no-repeat 0 2px; background-size: 13px; margin: 0 0 15px 0; }
.news-dtl-wrap { float: left; width: 100%; }
.news-dtl-wrap .news-dtl-head h1 { font-size: 28px; color: #000; float: left; width: 100%; margin: 0 0 20px 0; }

.news-dtl-wrap .news-dtl-head .newsdtl-ifo { display: flex; align-items: center; float: left; width: 100%; margin-bottom: 25px; flex-wrap: wrap; }

.news-dtl-wrap  .newsdtl-img { float: left; width: 100%; }

.news-dtl-wrap .news-dtl-head .newsdtl-ifo .sub { font-size: 13px; color: #707070; display: inline-block; margin: 0 30px 0 0; font-weight: normal; }

.news-dtl-wrap .news-dtl-head .newsdtl-ifo .date { font-size: 13px; color: #707070; display: inline-block; margin: 0 30px 0 0; font-weight: normal; }

.news-dtl-wrap .news-dtl-head .newsdtl-ifo .min { font-size: 13px; color: #3686f7; display: inline-block; margin: 0; font-weight: normal; padding-left: 20px; background: url(../images/time-icon.svg) no-repeat 0 4px; background-size: 15px; }
.news-dtl-wrap .news-dtl-head .newsdtl-ifo .viewcount { display: inline-block; color: #434343; margin: 0 20px; font-weight: normal; font-size: 13px; padding-left: 20px; background: url(../images/eye-icon.svg) no-repeat 0 4px; background-size: 15px; }
.news-dtl-wrap  .newsdtl-img img { float: left; width:auto;max-width: 100%; margin: 0 0 50px 0; }
.news-dtl-wrap   .social-newsdtl { display: flex; flex-wrap: wrap; flex-direction: column; width: 36px; padding: 0; margin: 0; position: absolute; left: 11%; top: 54vh; }
.news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { position: fixed; top: 100px; left: 11%; width: 36px; }

.news-dtl-wrap   .social-newsdtl li { display: inline-block; margin: 0 0 15px 0; list-style: none; }

.news-dtl-wrap  .social-newsdtl li a { background: #3686f7; margin: 0; width: 36px; height: 36px; padding: 0; display: flex; align-items: center; justify-content: center; border-radius: 5px; }

.news-dtl-wrap .social-newsdtl li a:hover { background: #000; }
.news-dtl-wrap .news-dtl-col { float: left; width: 100%; }

.news-dtl-wrap .news-dtl-col .brand-wrp { float: left; width: 100%; }

.news-dtl-wrap .news-dtl-col nav.navbar { float: left; width: 100%; margin: 50px 0; }
.news-dtl-wrap .row.row-two { margin-top: 50px; position: static; }

.related-news .related-block.card { float: left; width: 100%; margin-bottom: 15px; }

.related-news .related-block.card .card-body { float: left; width: 100%; position: relative; border: none; box-shadow: none; outline: none; padding: 0; }

.related-news .related-block.card .card-body span { position: absolute; left: 0; bottom: 0; width: 100%; background: rgba(0, 0, 0, 0.6); padding: 5px 20px 10px 20px; color: #fff; font-size: 13px; line-height: normal; max-height: 49px; }

.related-news .related-block.card .card-body .related-img { float: left; width: 100%; border-radius: 5px; overflow: hidden; height: 140px; }

.related-news .related-block.card .card-body .related-img img { width: 100%; height: 100%; object-fit: cover; }
/* .news-dtl-wrap .row.row-two .news-dtl-col-md-3 { position: relative; width: 295px; transition: ease all 0.5s; padding-right: 0; } */
.related-news .related-block.card:last-child { margin-bottom: 0; }
/* .news-dtl-wrap .row.row-two.stiky-row .news-dtl-col-md-3 { padding-right: 0; position: fixed; left: 1319px; top: 100px; height: 90vh; overflow-y: auto; } */

.news-dtl-wrap .row.row-two.stiky-move-row .news-dtl-col-md-3 { position: static; }
.news-dtl-wrap .news-footer-col { float: left; width: 100%; }

.news-dtl-wrap .news-footer-col .related-news { float: left; width: 100%; background: #fff; padding: 15px; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1); border-radius: 5px; display: flex; flex-wrap: wrap; flex-direction: column; }
.news-dtl-wrap .news-footer-col .related-news .row .col-md-3 { width: 25%; }
.news-dtl-wrap .news-footer-col .related-news .related-txt.latest-txt { background: #5d5d5d; color: #fff; padding: 0 10px; line-height: 20px; font-size: 11px; border-radius: 5px; height: 20px; float: left; margin-bottom: 10px; width: 96px; }
.news-dtl-wrap .news-footer-col .other-links.mt-2 { float: left; width: 100%; margin: 25px 0 0 0!important; color: #ccc; }

.news-dtl-wrap .news-footer-col .related-news .row .col-md-3 .related-block.card { height: 100%; border: none; }

.news-dtl-wrap .news-footer-col .related-news .row .col-md-3 .related-block.card .card-body { height: 100%; }

.news-dtl-wrap .news-footer-col .related-news .row .col-md-3 .related-block.card .card-body a { display: flex; width: 100%; flex-wrap: wrap; flex-direction: column-reverse; text-decoration: none; }

.news-dtl-wrap .news-footer-col .related-news .row .col-md-3 .related-block.card .card-body a span { position: static; padding: 10px 0; background: none; text-decoration: none; color: #000; float: left; height: auto; max-height: inherit; font-size: 14px; }

.news-dtl-wrap .news-footer-col .related-news .row .col-md-3 .related-block.card .card-body a .related-img { border-radius: 5px 5px 0 0; overflow: hidden; height: 140px; }

.news-dtl-wrap .news-footer-col .other-links a { color: #000; text-decoration: none; padding: 0 15px; transition: ease all 0.5s; margin-bottom: 10px; display: inline-block; }

.news-dtl-wrap .news-footer-col .other-links a:hover { color: #3686f7; }
.news-dtl-wrap .ad-wrp { float: left; width: 100%; margin-bottom: 20px; }
.news-dtl-wrap .ad-link { float: left; width: 100%; margin: 0 0 20px 0; }
.news-dtl-wrap p iframe { margin-top: 20px; }
[dir="rtl"]  .news-dtl-wrap .news-dtl-head .newsdtl-ifo .min { margin-right: 20px; }
[dir="rtl"]  .news-dtl-wrap .social-newsdtl {
  left: auto;
  right: 11%;
}
@media (max-width:1900px) {
  .news-dtl-wrap   .social-newsdtl { left: 8%; }
  [dir="rtl"] .news-dtl-wrap   .social-newsdtl { left: auto;  right: 8%;}
  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: 8%; }
  [dir="rtl"]  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: auto; right: 8%;}
}

@media (max-width:1750px) {
  .news-dtl-wrap  .social-newsdtl { left: 6%; }
  [dir="rtl"] .news-dtl-wrap  .social-newsdtl { left:auto; right: 6%;}
  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: 6%; }
  [dir="rtl"]  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: auto; right: 6%; }
}

@media (max-width:1680px) {
  .news-dtl-wrap  .social-newsdtl { left: 2%; }
  [dir="rtl"] .news-dtl-wrap  .social-newsdtl { left: auto;right: 2%; }
  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: 2%; }
  [dir="rtl"]   .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: auto; right: 2%;}
}
@media (max-width: 1520px) {
  .news-dtl-wrap  .social-newsdtl { left: 2%; }
  [dir="rtl"] .news-dtl-wrap  .social-newsdtl { left:auto; right: 2%;}
  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: 2%; }
  [dir="rtl"]   .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: auto; right: 2%; }
  .news-dtl-wrap  .social-newsdtl li a { width: 30px; height: 30px; }
}

@media (max-width: 1480px) {
  .news-dtl-wrap   .social-newsdtl { left: 1%; }
  [dir="rtl"] .news-dtl-wrap   .social-newsdtl { left: auto; right: 1%;}
  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: 1%; }
  [dir="rtl"]  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: auto; right: 1%; }
  .news-dtl-wrap  .social-newsdtl li a { width: 24px; height: 24px; }
}

@media (max-width: 1366px) {
  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: 1%; }
  [dir="rtl"]  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: auto; right: 1%;}
  .news-dtl-wrap  .social-newsdtl { left: 1%; }
  [dir="rtl"]  .news-dtl-wrap  .social-newsdtl { left: auto; right: 1%;}
}
@media (max-width: 1180px) {
  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: 1%; }
  [dir="rtl"]   .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: auto; right: 1%;}
  .news-dtl-wrap  .social-newsdtl { left: 1%; }  
  [dir="rtl"] .news-dtl-wrap  .social-newsdtl { left:auto; right: 1%;}
}
@media (max-width:991px) {
  .related-news .related-block.card .card-body .related-img { height: 100px; }
  .related-news .related-block.card .card-body span { font-size: 11px; padding: 5px 10px 10px 10px; }
  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: 7px; }
  [dir="rtl"]  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: auto; right: 7px; }
  .news-dtl-wrap  .social-newsdtl { left: 7px; }
  [dir="rtl"] .news-dtl-wrap  .social-newsdtl { left:auto; right: 7px;}
  .news-dtl-wrap .news-dtl-col { padding-left: 25px; }
  [dir="rtl"] .news-dtl-wrap .news-dtl-col { padding-left: 0; padding-right: 25px;}
  .news-dtl-wrap .news-footer-col .related-news .row .col-md-3 { width: 33.333%; margin-bottom: 15px; }
}

@media screen and (min-width:768px) and (max-width:991px) {
  .related-news .related-block.card .card-body span { font-size: 9px; padding: 5px 10px 10px 10px; max-height: 74%; }
}

@media (max-width: 767px) {
  .news-dtl-wrap  .social-newsdtl li a { width: 22px; height: 22px; }
  .news-dtl-wrap .row.row-two { margin-top: 25px; }
  .news-dtl-wrap .news-dtl-head h1 { font-size: 24px; }
  .news-dtl-wrap p { font-size: 15px; }
  .news-dtl-wrap .news-dtl-head .newsdtl-ifo span { margin-bottom: 10px!important; }
  .related-news .related-block.card .card-body .related-img { height: 170px; }
  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: 7px; z-index: 99; top: 100px; }
  [dir="rtl"]  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: auto; right: 7px;  }
  .news-dtl-wrap .social-newsdtl { left: 7px; top: 430px;}
  [dir="rtl"]  .news-dtl-wrap .social-newsdtl { left: auto; right: 7px; }
  .news-dtl-wrap .news-footer-col { padding-left: 30px; }
  [dir="rtl"]  .news-dtl-wrap .news-footer-col { padding-left: 0; padding-right: 30px;}
  .news-dtl-wrap .row.row-two.stiky-row .col-md-3 { padding-left: 40px; }
  [dir="rtl"] .news-dtl-wrap .row.row-two.stiky-row .col-md-3 { padding-left:15px; padding-right: 40px; }
  .news-dtl-wrap .news-footer-col .related-news .row .col-md-3 { padding: 15px; }
}
@media (max-width: 600px) {
  .news-dtl-wrap  .social-newsdtl { left: 7px; }
  [dir="rtl"] .news-dtl-wrap  .social-newsdtl { left: auto; right: 7px;}
  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: 7px; top: 100px; }
  [dir="rtl"]   .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: auto;  right: 7px;  }
  .news-dtl-wrap .news-footer-col .related-news .row .col-md-3 { width: 50%; }
}
@media (max-width:414px) {
  .news-dtl-wrap .news-dtl-head h1 { font-size: 22px; }
  .news-dtl-wrap .row.row-two { margin-top: 15px; }
  .related-news .related-block.card .card-body .related-img { height: 140px; }
  .news-dtl-wrap  .social-newsdtl { left: 7px; top: 410px; }
  [dir="rtl"] .news-dtl-wrap  .social-newsdtl { left: auto; right: 7px; }
  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: 7px; top: 100px; }
  [dir="rtl"]  .news-dtl-wrap .row.row-two.stiky-row .social-newsdtl { left: auto; right: 7px;  }
  .news-dtl-wrap .news-footer-col .related-news .row .col-md-3 { width: 100%; }
}

@media (max-width:360px) {
  .news-dtl-wrap .news-dtl-head h1 { font-size: 20px; }
}
