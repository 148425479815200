body {
  background-color: rgb(236, 236, 236);
}
.cartComponent {
  margin: 5rem;
  width: 55rem;
  height: auto;
  background-color: white;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding-bottom: 3rem;
  border-radius: 15px;
}
.cartComponent h2 {
  padding-top: 2rem;
  margin-left: 5rem;
  font-family: "Poppins", serif;
  font-weight: 400;
}
.price-subtotal p {
  font-family: "Poppins", serif;
  font-weight: 400;
  margin-top: 2rem;
  margin-left: 5rem;
}

.price-subtotal p span {
  float: right;
  margin-right: 9rem;
}
.price-subtotal hr {
  width: 43rem;
  margin-left: 5rem;
  margin-top: 1rem;
}
.price-total {
  font-family: "Poppins", serif;
  font-weight: 400;
  margin-top: 2rem;
  margin-left: 5rem;
}
.price-total p span {
  float: right;
  margin-right: 9rem;
}
.price-total button {
  margin-top: 1.5rem;
  width: 40rem;
  height: 2.5rem;
  border-radius: 5px;
  background-color: #3686f7;
  border: none;
  outline: none;
  color: white;
}
