.store-wrap{
    width: 100%;
}
.store-wrap .store-item-detail {
    width:100%;
    display:flex;
    flex-wrap:wrap;
}  
.store-wrap .store-item-detail .store-item-avathar {
    width:36%; 
    display:inline-block;
    padding: 10px 0px;
    margin: 0 5% 0 0;
} 
.store-wrap .store-item-detail .store-item-info {
    width:58%;
    display:inline-block;   
    padding: 10px 15px;
}
.store-wrap .store-item-detail .store-item-info .store-item-title{
    width: 100%;
    display: block;
}
.store-wrap .store-item-detail .store-item-info .store-item-title .title{
    font-size: 28px;
    font-weight: 600;
    margin: 0 0 20px;
    line-height: 1.25;
}
.store-wrap .store-item-detail .store-item-info .store-item-title .subtitle{
    width: 100%;
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: 500;
    color: #848282;
}
.store-wrap .store-item-detail .store-item-content{
    width:100%;
    display:inline-block;     
    padding: 10px 0px;
}

.store-action{ 
    width:100%;
    margin:15px 10px; 
}

.store-wrap .store-item-detail table tr > * {
    padding: 0 0 15px;
}

.store-wrap .store-item-detail table tr th {
    position: relative;
    padding-right: 18px;
    color: #606060;
    font-weight: 500;

}
.store-wrap .store-item-detail table tr td {
    color: #606060;
    font-weight: 500;
}
.store-wrap .store-item-detail .store-item-main > div img {
    border-radius: 7px;
  }

.store-wrap .store-item-detail table tr th:after {
    content: ":";
    position: absolute;
    right: 0;
    top: 0;
    margin: 0 8px 0 0;
}

.book-now-btn {
    border: 1px solid #3666f7;
    background: #3666f7;
    border-radius: 12px;
    color: #fff;
    padding: 12px 15px;
    text-decoration: none;
    min-width: 180px;
    display: block;
    width: fit-content;
    text-align: center;
}
.book-now-btn:hover {
    border: 1px solid #000000;
    background: #000000;
    border-radius: 12px;
    color: #fff;
    padding: 12px 15px;
    text-decoration: none;
    min-width: 180px;
    display: block;
    width: fit-content;
    text-align: center;
}

.store-item-gallery {
    width:100%;
    display:flex;
    flex-wrap:wrap;
}
.store-item-gallery .store-gallery-item {
    /* border: 1px solid #dadada; */
    margin: 0 3.5% 0 0;
    padding: 0;
    width: 22.35%;
    cursor: pointer;
}



.store-wrap .card .card-header span {
    font-size: 18px;
    font-weight: 600;
}

.store-wrap .card .card-header {
    background: transparent;
}

.store-wrap .col-md-9 .card .card-header {border: 0; padding: 18px 30px 5px;}

.store-wrap .card .input-field label.form-label {
    font-size: 15px;
    display: block;
    font-weight: 500;
    margin: 0 0 10px;
}

.mobile-fade.card {
    margin-top: 22px;
}

form.search-form.top-serch.m-2 {
    margin-left: 0 !important;
    padding-right: 12px !important;
}
.store-wrap .card .input-field .form-check {
    min-height: inherit;
    margin: 0 0 1px;
    display: block;
    padding: 8px 5px;
    width: 100%;
    display: flex;
}

.store-wrap .card .input-field .form-check label.form-check-label {
    font-size: 14px;
    color: #848282;
    cursor: pointer;
    display: inline-block;
    padding-left: 24px;
}
.search-field .search-field-dropdown .form-check:has(.form-check-input:checked) .form-check-label {color: #fff !important;}
.search-field-dropdown.input-group.has-validation .form-check:hover .form-check-label {color: #fff !important;}


.search-field-dropdown.input-group.has-validation label p {
    margin-bottom: 0;
}

.store-wrap .card .input-field .form-check-input[type=checkbox] {
    border-radius: 2px;
    border-color: #b3afaf;
    margin: 4px 0 0;
    cursor: pointer;
    position: absolute;
}

.store-wrap .col-md-9 .card .card-body {
    padding: 8px 30px 20px;
}

.store-wrap .top-action {
    width: 100%;
    display: block;
    float: left;
}

.store-wrap .top-action a {
    width: fit-content;
    text-decoration: none;
    margin: 0 0 20px;
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
}

.store-wrap .top-action a:hover {
    color: #3686f7;
}

.store-wrap .top-action a {
    padding-left: 32px !important;
    position: relative;
}

.store-wrap .top-action a:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    background: url(../images/left-arrow-white.svg) no-repeat 100%;
    margin: 1px 0 0;
}

.store-wrap .store-item-detail .store-item-main {
    width: 100%;
    display: block;
    /* border: 1px solid #DADADA; */
    margin: 0 0 28px;
    /* padding: 22px 40px; */
}

.store-wrap .store-item-detail img {
    width: 100%;
    display: block;
    height: 100%;
}

.store-wrap .store-item-detail .store-item-main > div {
    width: 100%;
    display: block;
}

.store-wrap .store-item-detail .store-item-main img {
    width: 100%;
    margin: 0;
    max-height: 612px;
    object-fit: contain;
}

.store-item-gallery .store-gallery-item:last-child {
    margin-right: 0;
}

.store-wrap .store-item-detail > * {
    width: fit-content;
    float: left;
    display: block;
}

.store-wrap .store-item-detail p {
    width: 100%;
}


body.hidesearchclass form.search-form.top-serch {
    display: none;
  }
  body.hidesearchclass .body-container {
    padding-top: 140px;
  }
  .hidesuccess .about-wrap {
    background: none;
    text-align: center;
  }
  .hidesuccess .about-wrap h2{margin-bottom: 20px; font-weight: 600; font-size: 29px;}
  .hidesuccess .col-md-9 {
    margin-inline: auto;
  }
  .hidesuccess .about-wrap .about-row .about-col p{color: #6C6C6C; font-weight: 500;}
  .hidesuccess .home-page-outer {
    padding-block: 4rem;
  }
  body.detail-classhide .body-container .container {
    display: flex;
    flex-direction: column;
  }
  body.detail-classhide .body-container .container .lower {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 30px;
  }
  body.detail-classhide .body-container .container .lower h2{
    width: 100%;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .authorclass p {
    display: inline;
    font-weight: 600;
    color: #606060;
}
.authorclass div {
    display: inline;
    margin-left: 7px;
    font-weight: 600;
    color: #606060;
}


  body.detail-classhide .body-container .container .lower > div .data{
display: flex;
  }
  body.detail-classhide .body-container .container .lower > div .data .product-card{
    margin-left: 0;
    margin-right: 2rem;
  }
  .store-item-title tr th {
    width: 20px !important;
    font-weight: 500;
    color: #606060;}
  .store-item-title tr td {
    font-weight: 500;
    color: #606060;}



.store-item-title h2 {
    margin-top: 15px;
}
.store-item-title h2 {
    margin-top: 15px;
    font-size: 23px;
    font-weight: 600;
}


.store-wrap .store-item-detail.lawyer-info .store-item-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 35px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 4px 1px #ddd;
    margin: 0 0 28px;
}

.store-wrap .store-item-detail.lawyer-info .store-item-info .store-item-title {
    width: 18%;
    margin: 0 4% 0 0;
}

.store-wrap .store-item-detail.lawyer-info .avathar {
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.store-wrap .store-item-detail.lawyer-info .store-item-info .details-section {
    width: 56.5%;
    margin: 0 3% 0 0;
}

.store-wrap .store-item-detail.lawyer-info .store-item-info .cart {
    width: 18.5%;
}

.store-wrap .store-item-detail.lawyer-info .store-item-info .title {
    font-size: 20px;
    color: #222222;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.45;
    margin: 0 0 6px;
    text-transform: capitalize;
}

.store-wrap .store-item-detail.lawyer-info .store-item-info .subtitle {
    font-size: 18px;
    color: #bfbfbf;
    letter-spacing: normal;
    line-height: 1.45;
    margin: 0 0 6px;
    text-transform: capitalize;
}

.store-wrap .store-item-detail.lawyer-info table tr > * {
    padding: 0 0 6px;
}

.store-wrap .store-item-detail.lawyer-info table tr th {
    width: fit-content;
}

.store-wrap .store-item-detail.lawyer-info table tr th:after {
    position: relative;
    right: -2px;
}

.store-wrap .store-item-detail.lawyer-info .cart-btn {
    font-size: 14px;
    letter-spacing: normal;
    line-height: normal;
    padding: 8px 12px;
    margin: 0 0 8px;
    border: 1px solid #0077c9;
    background: #0077c9;
    color: #ffffff;
    text-decoration: none;
    display: inline-block;
    position: relative;
    border-radius: 6px;
    text-align: center;
    transition: all 0.25s ease-in;
}

.store-wrap .store-item-detail.lawyer-info .cart-btn:hover {
    background: #222222;
    border-color: #222222;
}

.store-wrap .store-item-detail.lawyer-info .additional-info {
    width: 100%;
    margin: 0;
    border: 0;
    padding-bottom: 0;
}

.store-wrap .store-item-detail.lawyer-info .additional-info hr {
    display: none;
}

.store-wrap .store-item-detail.lawyer-info .additional-info h3 {
    font-size: 20px;
    color: #222222;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.45;
    margin: 0 0 18px;
    padding: 0 0 0 14px;
    position: relative;
    text-transform: capitalize;
}

.store-wrap .store-item-detail.lawyer-info .additional-info h3:before {
    content: "";
    width: 4px;
    height: 100%;
    background: #316bff;
    position: absolute;
    left: 0;
    top: 0;
}

.store-wrap .store-item-detail table td a {
    text-decoration: none;
    color: #0077c9;
}

.store-wrap .store-item-detail table td a:hover {
    color: #606060;
}

.store-wrap .store-item-detail.lawyer-info .additional-info .store-item-content {
    width: 100%;
    display: block;
    float: left;
    padding: 40px 35px;
    background: #ffffff;
    border-radius: 14px;
    border: 1px solid #ddd;
    margin: 20px 0 28px;
}

.store-wrap .store-item-detail.lawyer-info .additional-info p {
    font-size: 15px;
    color: #bfbfbf;
    letter-spacing: normal;
    line-height: 1.45;
    margin: 0 0 28px;
}

.store-wrap .store-item-detail.lawyer-info .additional-info h4 {
    font-size: 20px;
    color: #222222;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.45;
    margin: 0 0 18px;
    padding: 0 0 0 28px;
    position: relative;
}

/* licenses */

.store-wrap .store-item-detail.lawyer-info .licenses-info {
    width: 100%;
    display: block;
    float: left;
    padding: 40px 35px;
    background: #ffffff;
    border-radius: 14px;
    border: 1px solid #ddd;
    margin: 10px 0 28px;
}


@media screen and (max-width: 1200px) {
    .store-wrap .card .input-field label.form-label {
        font-size: 14px;
    }
    .store-wrap .card .input-field .form-check label.form-check-label {
        font-size: 14px;
    }
    .store-wrap .card .input-field .form-check-input[type=checkbox] {
        margin: 3px 0 0;
    }
}

@media screen and (max-width: 991px) {
    .store-wrap .card .input-field label.form-label {
        font-size: 15px;
    }
    .store-wrap .card .input-field .form-check label.form-check-label {
        font-size: 12px;
        padding: 0 0 0 20px;
    }
    .store-wrap .card .input-field .form-check-input[type=checkbox] {
        margin: 5px 0 0;
        width: 14px;
        height: 14px;
    }

    .store-wrap .col-md-3 {
        width: 30%;
    }
    
    .store-wrap .col-md-9 {
        width: 70%;
    }

    .store-wrap .store-item-detail.lawyer-info .store-item-info {
        padding: 30px 3%;
    }
    
    .store-wrap .store-item-detail.lawyer-info .additional-info .store-item-content {
        padding: 30px 3%;
        margin: 10px 0 28px;
    }

    .store-wrap .store-item-detail.lawyer-info .store-item-info .cart .book-now-btn {
        width: auto;
        min-width: auto;
    }
}

@media screen and (max-width: 767px) {
    .store-wrap .card .input-field label.form-label {
        font-size: 20px;
    }
    .store-wrap .card .input-field .form-check label.form-check-label {
        font-size: 17px;
    }
    .store-wrap .card .input-field .form-check-input[type=checkbox] {
        margin: 5px 0 0;
    }

    .store-wrap .col-md-3 {
        width: 100%;
        margin: 0 0 8px;
    }

    .store-wrap .col-md-9 {
        width: 100%;
    }

    .store-wrap {
        margin: 10px 0 0;
    }

    .store-wrap .store-item-detail.lawyer-info .store-item-info .cart .book-now-btn {
        font-size: 14px;
        padding: 10px 12px;
    }

    .store-wrap .store-item-detail.lawyer-info .additional-info h3 {
        font-size: 18px;
    }

    .store-wrap .store-item-detail.lawyer-info .store-item-info .title {
        font-size: 18px;
    }

    .store-wrap .store-item-detail.lawyer-info .store-item-info .subtitle {
        font-size: 16px;
    }

    .store-wrap .store-item-detail.lawyer-info table tr > * {
        font-size: 14px;
    }

    .store-wrap .store-item-detail.lawyer-info .additional-info .store-item-content {
        font-size: 14px;
    }
}

@media screen and (max-width: 576px) {
    .store-items .store-item:nth-child(2n) {
        margin-right: 0;
    }
    
    .store-items .store-item {
        width: 49%;
    }
    
    .store-items .store-item .store-item-body .store-avathar {
        height: 28vw;
    }
}

@media screen and (max-width: 480px) {
    .store-wrap .store-item-detail.lawyer-info .store-item-info .cart {
        margin: 1.5rem 0 0;
        width: 100%;
        text-align: center;
    }
    
    .store-wrap .store-item-detail.lawyer-info .store-item-info .details-section {
        width: 58%;
        margin: 0;
    }
    
    .store-wrap .store-item-detail.lawyer-info .store-item-info .store-item-title {
        width: 38%;
    }
}

@media screen and (max-width: 414px) {
    .store-items .store-item {
        width: 100%;
        margin: 0 0 30px;
    }
    
    .store-items .store-item .store-item-body .store-avathar {
        height: 51vw;
    }
    
    .store-items .store-item .store-item-body .store-avathar img {max-height: 100%;}
    
    .store-wrap .card .input-field .form-check label.form-check-label {
        font-size: 15px;
    }
    
    .store-wrap .card .input-field .form-check-input[type=checkbox] {
        margin: 4px 0 0;
    }
    
    .store-wrap .card .input-field label.form-label {
        font-size: 20px;
    }

    .store-wrap .store-item-detail.lawyer-info .store-item-info .store-item-title {
        width: 100%;
        margin: 0 0 1.2rem;
    }
    
    .store-wrap .store-item-detail.lawyer-info .store-item-info .details-section {
        width: 100%;
    }

}
