.customer-login {
  width: 28rem;
  height: auto;
  background-color: white;
  margin-top: 5rem;
  margin-left: -1rem;
  border-radius: 15px;
}
.customer-login h2 {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-size: 20px;
  margin-left: 2rem;
  padding-top: 2rem;
}
.customer-login input {
  display: block;
  margin-left: 3rem;
  width: 22rem;
  height: 2rem;
  padding: 5px;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid grey;
  outline: none;
}
.customer-login p {
  margin-left: 3rem;
  font-family: "Poppins", serif;
  font-weight: 400;
  font-size: 12px;
  margin-top: 0.5rem;
  color: grey;
  cursor: pointer;
}

.customer-login button {
  margin-left: 3rem;
  width: 22rem;
  height: 2rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid grey;
  background-color: #3686f7;
  color: white;
  outline: none;
  cursor: pointer;
}
