.contact-wrap {
    float: left;
    width: 101%;
    padding: 30px;
    padding-right: 5px;
    background: #fff;
    border-radius: 5px;
}
.contact-wrap .contact-row .contact-col .card .card-body form h2 {
    float: left;
    width: 100%;
    color: #000;
    font-size: 28px;
    margin-bottom: 20px;
}

.contact-wrap .contact-row .contact-col .card {
    display: inline-block;
    width: 100%;
    border: none;
    border-radius: 5px;
}

.contact-wrap .contact-row .contact-col .card .card-body {
    padding: 20px 30px 30px 30px;
}


.contact-wrap .contact-row .contact-col .card .card-body form h2 {
    float: left;
    width: 100%;
}

.contact-wrap .contact-row .contact-col .card .card-body form .input-field label.form-label {
    display: none;
}

.contact-wrap .contact-row .contact-col .card .card-body form .input-field  input.form-control {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 15px;
}

.contact-wrap .contact-row .contact-col .card .card-body form .input-field input.form-control::placeholder {
    font-size: 15px;
}

.contact-wrap .contact-row .contact-col .card .card-body button.btn.btn-primary {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #3686f7;
    font-size: 15px;
    color: #fff;
    background: #3686f7;
    padding: 0 30px;
    font-weight: 500;
    margin-top: 15px;
}

.contact-wrap .contact-row .contact-col .card .card-body button.btn.btn-primary:hover {
    border: 1px solid #000;
    color: #fff;
    background: #000;
}
.contact-wrap .contact-row .contact-col .card .card-body form   textarea.form-control {
    min-height: 110px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 15px;
    resize: vertical;
}
.contact-wrap .contact-row.row .contact-col h2 {
    float: left;
    width: 100%;
}

.contact-wrap .contact-row.row .contact-col p {
    float: left;
    width: 100%;
}

.contact-wrap .contact-row.row .contact-col form {
    float: left;
    width: 100%;
}

.contact-wrap .contact-row.row .contact-col form .input-field {
    float: left;
    width: 100%;
}

.contact-wrap .contact-row.row .contact-col form .input-field input.form-control {
    background: #fff;
    border-radius: 6px;
    color: #000;
    font-size: 14px;
    height: 45px;
    padding: 0 15px;
    border: 1px solid #ddd;
}

.contact-wrap .contact-row.row .contact-col form  button.btn.btn-primary {
    background: #3686f7;
    border: 1px solid #3686f7;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    height: 45px;
    padding: 0 25px;
    transition: all .5s ease;
}

.contact-wrap .contact-row.row .contact-col form button.btn.btn-primary:hover {
    background: #000;
    border: 1px solid #fff;
    color: #fff;
    height: 45px;
}
.contact-wrap .contact-row.row .contact-col form .mb-3 label.form-label {
    display: none;
}
.contact-wrap .contact-row.row .contact-col .alert.alert-success {
    min-height: inherit !important;
    height: auto !important;
    float: left;
    width: 100%;
}
[dir="rtl"] .contact-wrap {
    float: right;
}
@media (max-width:767px) {
.contact-wrap .contact-row .contact-col .card .card-body {
    padding: 0  ;
}
.contact-wrap {
    width: 100%;
}
 
}
