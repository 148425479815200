.body-container .home-page-outer { float: left; width: 100%; }
.search-click { border: 1px solid #ccc; outline: none; background-size: 22px; background-position: 13px; border-radius: 5px; width: 50px; height: 50px; padding: 25px; transition: all 0.5s; }
.search-click:focus { width: 300px; padding-left: 50px; }
.search-click { position: relative; overflow: hidden; height: 50px; }
.search-click input { background: transparent; border: 1px solid #ccc; outline: none; position: absolute; width: 300px; height: 50px; left: 0; padding: 10px; }

.row.row-one .col-md-3 { width: 23%; }

.row.row-one .col-md-4 { width: 35.333333%; }

.body-container .main-menu .col-md-3 { width: 23%; }

.body-container .main-menu .col-md-9 { width: 77%; }
.body-container .brand-wrp { float: left; width: 100%; margin-bottom: 45px; }

.body-container .brand-wrp .slider-wrap { float: left; width: 100%; }
.body-container  .main-menu { padding-top: 0; padding-bottom: 20px; }

.body-container .main-menu .search-news { display: flex; width: 100%; }
.body-container .main-menu .search-news input.form-control { height: 45px; background: #fff; display: inline-block; width: 73%; padding: 0 20px; color: #222; border: 1px solid #fff; border-radius: 5px 0 0 5px; font-size: 15px; }
.body-container .main-menu .search-news input.form-control::placeholder { font-size: 15px; }
.body-container .main-menu .search-news input.form-control::placeholder { color: #b3b3b3; }
.body-container .main-menu .search-news button.search-btn { height: 45px; background: url(../images/search-icon.svg) #3686f7 no-repeat center center; background-size: 20px; display: inline-block; width: 27%; border-radius: 0 5px 5px 0; padding: 0 20px; color: #fff; position: relative; border: 1px solid #3686f7; transition: ease all 0.5s; }

.body-container .main-menu .search-news button.search-btn:hover { background: url(../images/search-icon.svg) #222 no-repeat center center; background-size: 20px; border: 1px solid #222; }

/* section1 */
.row-one .block-col1 .news-block { display: flex!important; width: 100%!important; border-radius: 5px; background: #fff; overflow: hidden; position: relative; float: left; flex-direction: column; border: none!important; outline: none!important; box-shadow: none!important; }
.row-one .block-col1 .news-block .card-body { border: none!important; outline: none!important; box-shadow: none!important; padding: 0; display: flex; width: 100%; flex-wrap: wrap; flex-direction: row; }
.row-one .block-col1 .news-block .trending { display: flex; width: 100%; background: #fff; padding: 10px 15px; height: 40px; overflow: hidden; }

.row-one .block-col1 .news-block .trending .hd { background: #ff302f; color: #fff; padding: 0 10px; line-height: 20px; font-size: 12px; border-radius: 5px; height: 20px; margin-right: 10px; }

.row-one .block-col1 .news-block .trending p { color: #000; line-height: 25px; font-size: 15px; margin: 0; font-weight: 600; letter-spacing: normal; }

.row-one .block-col1 .news-block .news-content { float: left; width: 100%; position: absolute; background: rgba(0,0,0,0.6); left: 0; bottom: 0; height: auto; z-index: 99; padding: 20px 35px; margin: 0; }

.row-one .block-col1 .news-block .news-content p { float: left; width: 100%; margin: 0 0 5px 0; font-size: 11px; color: #fff; font-weight: normal; }

.row-one .block-col1 .news-block .news-content h2 { float: left; width: 100%; margin: 0; font-size: 18px; color: #fff; }
.row-one .block-col1 .news-block .news-img { display: inline-block; width: 100%; height: 100%; }

.row-one .block-col1 .news-block .news-img img { width: 100%; height: 100%; object-fit: cover; }
.row-one .block-col1 .slick-slider.news-slider .news-block .card-body { border-radius: 0 0 5px 5px!important; }

.row-one .block-col1 .slick-slider.news-slider .news-block .card-body .news-img { border-radius: 0 0 5px 5px!important; }

.row-one .block-col1 .slick-slider.news-slider .news-block .card-body .news-img img.card-img { border-radius: 0 0 5px 5px!important; }
.row.split-row .slick-slider.news-slider .slick-list { height: 100%; border-radius: 0 0 5px 5px!important; }
.row.split-row .slick-slider.news-slider .slick-list .slick-track { height: 100%; }
.row.split-row .slick-slider.news-slider .slick-list .slick-track { height: 100%; }
.row.split-row .slick-slider.news-slider .slick-list .slick-track .slick-slide { height: 100%; }
.row.split-row .slick-slider.news-slider .slick-list .slick-track .slick-slide>div { height: 100%; }
.row.split-row .slick-slider.news-slider .slick-list .slick-track .slick-slide > div a { height: 100%; }
.row.split-row .slick-slider.news-slider .slick-list .slick-track .slick-slide > div a .news-block.card { height: 100%; }
.row-one .block-col1 .news-slider button.slick-arrow.slick-prev { width: 32px; height: 45px; background: url("../images/right-arrow-white.svg") #3686f7 no-repeat center center; background-size: 8px; left: 0; z-index: 99; transition: ease all 0.5s; }
.row-one .block-col1 .news-slider button.slick-arrow.slick-prev:hover { background: url("../images/right-arrow-white.svg") #222 no-repeat center center; background-size: 8px; }
.row-one .block-col1 .news-slider button.slick-arrow.slick-next { width: 32px; height: 45px; background: url("../images/right-arrow-white.svg") #3686f7 no-repeat center center; background-size: 8px; right: 0; z-index: 99; transition: ease all 0.5s; }
.row-one .block-col1 .news-slider button.slick-arrow.slick-next:hover { background: url("../images/right-arrow-white.svg") #222 no-repeat center center; background-size: 8px; }
.row-one .block-col1 .news-slider button.slick-arrow.slick-next::before, .row-one .block-col1 .news-slider button.slick-arrow.slick-prev::before { display: none; }
.row-one .block-col1 { float: left; width: 100%; }

.row-one .block-col2 { float: left; width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between; }

.row-one .block-col2 .latest-wrap { display: inline-block; width: 47%; background: #fff; padding: 10px 15px; border-radius: 5px; }
.row.row-one .col-md-5 { padding-right: 5px; padding-left: 5px; }
.row-one .block-col2  .news-list { display: inline-block; width: 50%; background: #fff; padding: 25px 15px; border-radius: 5px; position: relative; }
.row-one .block-col2 .news-list .seemore { font-size: 14px; text-decoration: none; color: #3686f7; display: inline-block; position: relative; float: right; margin-top: -17px; transition: ease all 0.5s; }
.row-one .block-col2 .news-list .seemore:hover { color: #000; }
.row-one .block-col2 .news-list .news-list-block.card:nth-child(2) { margin-bottom: 0; }
.row.row-one { margin-bottom: 15px; }
.row-one .block-col2 .latest-wrap .latest-txt { background: #5d5d5d; color: #fff; padding: 0 10px; line-height: 20px; font-size: 12px; border-radius: 5px; height: 20px; float: left; margin-bottom: 10px; }
.main-menu .col-md-9 { padding-right: 5px; }

.row.row-one .col-md-5 { padding-right: 5px; padding-left: 5px; }
.row-one .block-col2 .latest-wrap .latest-block.card { float: left; width: 100%; position: relative; margin-bottom: 20px; border-radius: 5px; overflow: hidden; border: none; box-shadow: none; outline: none; padding: 0; }
.row-one .block-col2 .latest-wrap .latest-block.card .card-body { border: none; box-shadow: none; outline: none; padding: 0; }
.row-one .block-col2 .latest-wrap .latest-block span { position: absolute; left: 0; bottom: 0; width: 100%; background: rgba(0, 0, 0, 0.6); padding: 5px 20px 10px 20px; color: #fff; font-size: 13px; line-height: normal; max-height: 49px; }

.row-one .block-col2 .latest-wrap .latest-block .latest-img { float: left; width: 100%; border-radius: 5px; overflow: hidden; height: 140px; }

.row-one .block-col2 .latest-wrap .latest-block .latest-img img { width: 100%; height: 100%; object-fit: cover; }

.row-one .block-col2 .latest-wrap .latest-block:last-child { margin-bottom: 0; }
.row-one .block-col2 .news-list .news-list-block.card { float: left; width: 100%; margin-bottom: 20px; border: none; box-shadow: none; outline: none; padding: 0; }
.row-one .block-col2 .news-list .news-list-block.card .card-body { border: none; box-shadow: none; outline: none; padding: 0; }
.row-one .block-col2 .news-list .news-list-block .news-list-top { display: flex; width: 100%; flex-wrap: wrap; align-items: center; justify-content: space-between; }

.row-one .block-col2 .news-list .news-list-block .news-list-top .list-top-lft { display: inline-block; width: 50%; text-align: left; font-size: 10px; line-height: normal; padding-left: 15px; background: url("../images/doticon-blue.svg") no-repeat 0 center; background-size: 8px; }

.row-one .block-col2 .news-list .news-list-block .news-list-top .list-top-rgt { display: inline-block; font-size: 11px; line-height: normal; text-align: right; width: auto; max-width: 50%; }

.row-one .block-col2 .news-list .news-list-block h2 { float: left; width: 100%; font-size: 18px; line-height: normal; color: #000; max-height: 110px; overflow: hidden; }

.row-one .block-col2 .news-list .news-list-block h4 { float: left; width: 100%; font-size: 14px; line-height: normal; color: #969696; }

.row-one .block-col2 .news-list .news-list-block .news-list-bottom { display: flex; flex-wrap: wrap; width: 100%; justify-content: space-between; align-items: center; }

.row-one .block-col2 .news-list .news-list-block .news-list-bottom .list-btm-lft { display: flex; width: 55%; align-items: center; }

.row-one .block-col2 .news-list .news-list-block .news-list-bottom .list-btm-lft .like { display: inline-block; color: #434343; font-size: 11px; padding-left: 18px; background: url("../images/like-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; margin-right: 15px; }

.row-one .block-col2 .news-list .news-list-block .news-list-bottom .list-btm-lft .dislike { display: inline-block; color: #434343; font-size: 11px; padding-left: 18px; background: url("../images/dislike-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; }
.row-one .block-col2 .news-list .news-list-block .news-list-bottom .list-btm-lft .viewcount { display: inline-block; color: #434343; font-size: 11px; padding-left: 18px; background: url("../images/eye-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; margin-right: 15px; }
.row-one .block-col2 .news-list .news-list-block .news-list-bottom .list-btm-lft .comment { display: inline-block; color: #434343; font-size: 11px; padding-left: 18px; background: url("../images/comment-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; margin-right: 15px; }
.row-one .block-col2 .news-list .news-list-block .news-list-bottom  .list-btm-rgt { display: flex; width: 40%; align-items: center; }

.row-one .block-col2 .news-list .news-list-block.card:last-child { margin-bottom: 0; }

/* section2 */
.row.row-two { margin-bottom: 15px; }

.row.row-two .col-md-4 { width: 35.333333%; }

.row.row-two .col-md-5 { padding-right: 5px; padding-left: 5px; }

.row.row-two .col-md-3 { width: 23%; }

.row.row-two .block-col1 { border: none!important; outline: none!important; box-shadow: none!important; }
.row.row-two .col-md-9 { width: 77%; }

.row.row-two .col-md-9 .row.split-row { justify-content: space-between; display: flex; }

.row.row-two .col-md-9 .row.split-row .col-md-6.block-col1 { width: 50%; padding-right: 5px; }

.row.row-two .col-md-9 .row.split-row .col-md-6.block-col2 { width: 49%; }
.row.row-two .split-row.row-one .block-col2 .latest-wrap { width: 45%; max-height: 367px; -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); border-radius: 5px; }
.row.row-two .col-md-9 .row.split-row.row-two .col-md-12.block-col1 { padding-right: 5px; }

.row.row-two .col-md-9 .row.split-row.row-one  .col-md-6.block-col2 { padding: 0 5px 0 0; background: none; }

.row.row-two .split-row.row-one .block-col2 .news-list { overflow: hidden; width: 52%; max-height: 367px; -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); border-radius: 5px; }
.row.row-two .block-col1 .ads-block.card { border: none!important; outline: none!important; box-shadow: none!important; padding: 0; display: flex; width: 100%; flex-wrap: wrap; flex-direction: row; }

.row.row-two .block-col1 .ads-block.card .card-body { border: none!important; outline: none!important; box-shadow: none!important; padding: 0; display: flex; width: 100%; flex-wrap: wrap; flex-direction: row; }

.row.row-two .block-col1 .ads-block.card .card-body .news-img { display: inline-block; width: 100%; height: 352px; }

.row.row-two .block-col1 .ads-block.card .card-body .news-img img { width: 100%; height: 100%; }

.row.row-two .block-col2 { float: left; display: flex; flex-wrap: wrap; justify-content: space-between; background: none; padding: 0 5px 0 0; }
.row.row-two .col-md-9 .row.split-row .col-md-6.block-col2 .latest-wrap.latest-wrap-row { background: #fff; padding: 10px 15px; border-radius: 5px; height: 352px; overflow: hidden; display: inline-block; }
.row.row-two .block-col2 .latest-wrap { float: left; width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between; }

.row.row-two .block-col2 .latest-wrap .latest-txt { background: #5d5d5d; color: #fff; padding: 0 10px; line-height: 20px; font-size: 11px; border-radius: 5px; height: 20px; float: left; margin-bottom: 10px; }

.row.row-two .block-col2 .latest-wrap  .news-slider2-wrap { float: left; width: 100%; position: relative; padding-top: 0; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 button.slick-arrow.slick-next { width: 25px; height: 25px; background: url("../images/right-arrow-white.svg") #5d5d5d no-repeat center center; background-size: 6px; right: 0; z-index: 99; transition: ease all 0.5s; left: auto; top: -17px; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 button.slick-arrow.slick-next:hover { background: url("../images/right-arrow-white.svg") #000 no-repeat center center; background-size: 6px; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 button.slick-arrow.slick-prev { width: 25px; height: 25px; background: url("../images/left-arrow-white.svg") #5d5d5d no-repeat center center; background-size: 6px; right: 35px; z-index: 99; transition: ease all 0.5s; left: auto; top: -17px; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 button.slick-arrow.slick-prev:hover { background: url("../images/left-arrow-white.svg") #000 no-repeat center center; background-size: 6px; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .latest-block { display: flex; flex-wrap: wrap; width: 100%; justify-content: space-between; margin-bottom: 0; }
.row.row-two .split-row.row-one .block-col2 .latest-wrap a { float: left; width: 100%; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .latest-block { margin-bottom: 20px; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .latest-block:last-child { margin-bottom: 0; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 button.slick-arrow.slick-next::before, .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 button.slick-arrow.slick-prev::before { display: none; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card { float: left; width: 100%; position: relative; margin-bottom: 0; border-radius: 5px; overflow: hidden; border: none; box-shadow: none; outline: none; padding: 0; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body { border: none; box-shadow: none; outline: none; padding: 0; display: flex; flex-wrap: wrap; width: 100%; justify-content: space-between; position: relative; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-left { display: inline-block; width: 44%; position: relative; height: 134px; border-radius: 5px; overflow: hidden; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-left .latest-name { position: absolute; left: 0; bottom: 0; width: 100%; background: rgba(0, 0, 0, 0.6); padding: 5px 20px; color: #fff; font-size: 13px; line-height: normal; text-align: center; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-left .latest-img { float: left; width: 100%; border-radius: 5px; overflow: hidden; height: 100%; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-left .latest-img img { height: 100%; width: 100%; object-fit: cover; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right { display: inline-block; width: 52%; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-top { display: flex; width: 100%; flex-wrap: wrap; align-items: center; justify-content: space-between; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-top .list-top-lft { display: inline-block; width: 50%; text-align: left; font-size: 10px; line-height: normal; padding-left: 15px; background: url("../images/doticon-blue.svg") no-repeat 0 center; background-size: 8px; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-top .list-top-rgt { display: inline-block; width: auto; max-width: 50%; text-align: right; font-size: 11px; line-height: normal; float: right; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right h2 { float: left; width: 100%; font-size: 18px; line-height: normal; color: #000; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right h4 { float: left; width: 100%; font-size: 14px; line-height: normal; color: #969696; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom { display: flex; flex-wrap: wrap; width: 100%; justify-content: space-between; align-items: center; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom .list-btm-lft { display: flex; width: 55%; align-items: center; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom .list-btm-lft .like { display: inline-block; color: #434343; font-size: 12px; padding-left: 18px; background: url("../images/like-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; margin-right: 15px; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom .list-btm-lft .viewcount { display: inline-block; color: #434343; font-size: 12px; padding-left: 18px; background: url("../images/eye-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; margin-right: 15px; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom .list-btm-lft .comment { display: inline-block; color: #434343; font-size: 12px; padding-left: 18px; background: url("../images/comment-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; margin-right: 15px; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom .list-btm-lft .dislike { display: inline-block; color: #434343; font-size: 12px; padding-left: 18px; background: url("../images/dislike-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; }

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom .list-btm-rgt { display: flex; width: 40%; align-items: center; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .seemore { font-size: 14px; text-decoration: none; color: #3686f7; display: inline-block; float: right; transition: ease all 0.5s; position: absolute; right: 0; bottom: 16px; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .seemore:hover { color: #000; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block { float: left; width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between; background: #fff; padding: 10px 15px; border-radius: 5px; height: 352px; overflow: hidden; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-txt { background: #5d5d5d; color: #fff; padding: 0 10px; line-height: 18px; font-size: 11px; border-radius: 5px; height: 20px; float: left; margin-bottom: 10px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card { display: flex; width: 100%; border: none; outline: none; box-shadow: none; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body { display: flex; width: 100%; border: none; outline: none; box-shadow: none; flex-wrap: wrap; justify-content: space-between; padding: 0; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block { display: inline-block; width: 47%; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img { float: left; width: 100%; border-radius: 5px; overflow: hidden; position: relative; height: 134px; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img .latest-name { position: absolute; left: 0; bottom: 0; width: 100%; background: rgba(0, 0, 0, 0.6); padding: 5px 15px; color: #fff; font-size: 13px; line-height: normal; text-align: center; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img .latest-img { float: left; width: 100%; border-radius: 5px; overflow: hidden; height: 100%; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img .latest-img img { width: 100%; height: 100%; object-fit: cover; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt { display: inline-block; width: 100%; padding-top: 7px; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-top { display: flex; width: 100%; flex-wrap: wrap; align-items: center; justify-content: space-between; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-top .list-top-lft { display: inline-block; width: 62%; text-align: left; font-size: 12px; line-height: normal; padding-left: 13px; background: url("../images/doticon-blue.svg") no-repeat 0 center; background-size: 8px; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-top .list-top-rgt { display: inline-block; font-size: 12px; line-height: normal; text-align: right; width: auto; max-width: 38%; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt h2 { float: left; width: 100%; font-size: 17px; line-height: normal; color: #000; max-height: 77px; overflow: hidden; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt h4 { float: left; width: 100%; font-size: 14px; line-height: normal; color: #969696; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom { display: flex; flex-wrap: wrap; width: 100%; justify-content: space-between; align-items: center; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom .list-btm-lft { display: flex; width: 55%; align-items: center; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom .list-btm-lft .like { display: inline-block; color: #434343; font-size: 12px; padding-left: 18px; background: url("../images/like-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; margin-right: 15px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom .list-btm-lft .viewcount { display: inline-block; color: #434343; font-size: 12px; padding-left: 18px; background: url("../images/eye-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; margin-right: 15px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom .list-btm-lft .comment { display: inline-block; color: #434343; font-size: 12px; padding-left: 18px; background: url("../images/comment-icon.svg ") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; margin-right: 15px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom .list-btm-lft .dislike { display: inline-block; color: #434343; font-size: 12px; padding-left: 18px; background: url("../images/dislike-icon.svg") no-repeat 0 center; background-size: 15px; min-width: 20px; height: 15px; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt .news-list-bottom .list-btm-rgt { display: flex; width: 40%; align-items: center; }
.row.row-two .col-md-9 .row.split-row.row-three { margin-bottom: 15px; margin-left: 0; margin-right: -7px; }
.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 { padding-left: 0; padding-right: 0; width: 46%; }

.row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col2 { padding-left: 0; width: 54%; padding-right: 0; }
.slick-slider.news-slider2.no-slide button.slick-arrow.slick-prev { display: none!important; }
.slick-slider.news-slider2.no-slide button.slick-arrow.slick-next { display: none!important; }
.row.row-two .split-row.row-one .block-col2 .news-list a { float: left; width: 100%; }

.row.row-two .split-row.row-one .block-col2 .news-list a:first-child { margin-bottom: 20px; }

.row.row-two .split-row.row-one .block-col2 .news-list a.seemore { width: auto!important; float: right; }
.row.row-two .split-row.row-three .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .seemore { bottom: 17px; }

/* Recent post */

.recent-post { background: #fff; padding: 10px 15px; border-radius: 5px; float: left; width: 100%; -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); border-radius: 5px; }

.recent-post .latest-txt { background: #5d5d5d; color: #fff; padding: 0 10px; line-height: 20px; font-size: 11px; border-radius: 5px; height: 20px; float: left; margin-bottom: 10px; }

.recent-post .recent-block.card { float: left; width: 100%; margin-bottom: 15px; }

.recent-post .recent-block.card .card-body { float: left; width: 100%; position: relative; border: none; box-shadow: none; outline: none; padding: 0; }

.recent-post .recent-block.card .card-body span { position: absolute; left: 0; bottom: 0; width: 100%; background: rgba(0, 0, 0, 0.6); padding: 5px 20px 10px 20px; color: #fff; font-size: 13px; line-height: normal; }

.recent-post .recent-block.card .card-body .recent-img { float: left; width: 100%; border-radius: 5px; overflow: hidden; height: 140px; }

.recent-post .recent-block.card .card-body .recent-img img { width: 100%; height: 100%; object-fit: cover; }

.recent-post .recent-block.card:last-child { margin-bottom: 0; }
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .latest-block a { display: flex; flex-wrap: wrap; justify-content: space-between; width: 100%; text-decoration: none; }

.news-list-block .content p { float: left; width: 100%; display: none; }

.news-list-block .content strong { float: left; width: 100%; }
.row.row-two .news-col-wrap:nth-child(even).news-col-slider .latest-txt { opacity: 0; }
/* Recent post */

.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2.no-slide .slick-track .card-body .latest-block:last-child { margin-bottom: 20px; }
.not-available-text { text-align: center; float: left; width: 100%; }
.row.empty-search-row { margin-top: 30px; }
[dir="rtl"] .body-container .main-menu .search-news button.search-btn { border-radius: 5px 0 0 5px; }
[dir="rtl"] .row.row-two .split-row.row-one .block-col2 .news-list a.seemore { float: left; }
[dir="rtl"]  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .latest-block a { flex-direction: row-reverse; }
[dir="rtl"]  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right { text-align: right; }
[dir="rtl"] .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-top .list-top-rgt { float: right; }
[dir="rtl"] .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-top { float: right; justify-content: end; }
[dir="rtl"] .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom { flex-direction: row-reverse; text-align: right; }
[dir="rtl"] .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom .list-btm-lft { text-align: right; float: right; flex-direction: row-reverse; }

[dir="rtl"] .row-one .block-col1 .news-block .news-content h2 { text-align: right; }
[dir="rtl"]  .row-one .block-col1 .news-slider button.slick-arrow.slick-prev { right: auto!important; left: 0!important; background: url("../images/left-arrow-white.svg") #3686f7 no-repeat center center; background-size: 8px; }
[dir="rtl"]  .row-one .block-col1 .news-slider button.slick-arrow.slick-prev:hover { right: auto!important; left: 0!important; background: url("../images/left-arrow-white.svg") #000 no-repeat center center; background-size: 8px; }
[dir="rtl"] .row-one .block-col1 .news-slider button.slick-arrow.slick-next { left: auto!important; right: 0!important; }
[dir="rtl"]  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom .list-btm-lft .viewcount { margin-right: 0; margin-left: 15px; }
[dir="rtl"] .row.row-two .col-md-9 .row.split-row .col-md-6.block-col1 { padding-left: 10px; }
[dir="rtl"] .row.row-two .col-md-9 .row.split-row.row-one .col-md-6.block-col2 { padding-left: 12px; padding-right: 0; width: 49.5%; }
[dir="rtl"] .body-container .main-menu .search-news input.form-control { border-radius: 0 5px 5px 0; }
[dir="rtl"] .row.row-two .block-col2 .latest-wrap.latest-wrap-row .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-top { justify-content: flex-start; }
[dir="rtl"] .row.row-two .block-col2 .latest-wrap.latest-wrap-row .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom { flex-direction: row-reverse; justify-content: right; }

[dir="rtl"] .row.row-two .block-col2 .latest-wrap.latest-wrap-row .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom .list-btm-lft { flex-direction: row; justify-content: flex-start; width: 100%; }
[dir="rtl"] .news-col-wrap.news-col-slider .row.split-row.row-three .block-col2   .latest-wrap.latest-wrap-row .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-top { justify-content: end; }

[dir="rtl"] .news-col-wrap.news-col-slider .row.split-row.row-three .block-col2 .latest-wrap.latest-wrap-row .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom { flex-direction: row-reverse; }

[dir="rtl"] .news-col-wrap.news-col-slider .row.split-row.row-three .block-col2 .latest-wrap.latest-wrap-row .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom .list-btm-lft { justify-content: end; }
[dir="rtl"] .row-one .block-col2 .news-list .news-list-block .news-list-bottom .list-btm-lft .viewcount { margin-right: 0; margin-left: 15px; }
[dir="rtl"] .recent-post .latest-txt { float: right; }
@media (max-width:991px) {
  .body-container .main-menu { padding-top: 20px; padding-bottom: 20px; }
  .row.row-two .col-md-9 .row.split-row.row-one .col-md-6.block-col2 { padding-left: 5px; }
  .row-one .block-col1 .news-block .news-content h2 { font-size: 15px; }
  .row-one .block-col1 .news-block .news-content { padding: 10px 15px; }
  .body-container { padding-top: 215px; }
  [dir=rtl] .body-container .main-menu .col-md-9 { padding-right: 15px; }
  [dir="rtl"] .row.row-two .col-md-9 .row.split-row .col-md-6.block-col1 { padding-right: 10px; }
  [dir="rtl"] .row.row-two .col-md-9 { padding-right: 15px; padding-left: 15px; }
  [dir="rtl"] .home-page-outer .row.row-two .col-md-3 { padding-right: 0; }
  [dir="rtl"] .row.row-two .col-md-9 .row.split-row.row-three { margin-right: 0; }
  [dir="rtl"] .home-page-outer .row.row-two .row .col-md-6.news-col-wrap.news-col-slider { padding-right: 5px; }
}

@media screen and (min-width:768px) and (max-width:991px) {
  .row-one .block-col2 .news-list .news-list-block h2 { font-size: 15px; max-height: 45px; }
  .row-one .block-col2 .news-list .news-list-block .news-list-top .list-top-rgt { font-size: 9px; max-width: 50%; }
  .news-list-block .content p { font-size: 11px; }
  .row-one .block-col2 .latest-wrap .latest-block span { font-size: 10px; max-height: 40px; line-height: normal; padding: 5px 10px 10px 10px; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-12.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img .latest-name { font-size: 10px; max-height: 40px; line-height: normal; padding: 5px 10px 10px 10px; }
  .body-container .main-menu .search-news input.form-control { padding: 0 10px; font-size: 14px; width: 72%; }
  .body-container .main-menu .search-news input.form-control::placeholder { font-size: 14px; }
  .body-container .main-menu .search-news button.search-btn { width: 25%; padding: 0 10px; background-size: 17px; }
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2.no-slide .slick-track .card-body .latest-block:last-child { margin-bottom: 25px; }
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-left { height: 100px; }
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-bottom .list-btm-lft .viewcount { font-size: 9px; background-size: 12px; line-height: 18px; }
}

@media (max-width:860px) {
  .search-form label.custom-select select.form-select { height: 40px; font-size: 14px; }
  .search-form input.form-control { height: 40px; }
  .search-form button.search-btn { height: 40px; background-size: 16px; }
  .search-form .custom-select:after { font-size: 75%; padding: 2px; top: 9px; }
  .search-wrap { padding: 20px 0; }
  .body-container { padding-top: 190px; }
  .row.row-two .split-row.row-one .block-col2 .news-list a.seemore { font-size: 12px; }
  .row.row-two .col-md-9 .row.split-row.row-one .col-md-6.block-col2 { width: 100%; padding-left: 15px; padding-right: 5px; }
  .row.row-two .col-md-9 .row.split-row .col-md-6.block-col1 { width: 100%; margin-bottom: 15px; padding-left: 15px; padding-right: 5px; }
  .body-container .main-menu .col-md-9 { padding-left: 15px; }
  [dir="rtl"] .row.row-two .col-md-9 .row.split-row.row-one .col-md-6.block-col2 { width: 100%; padding-right: 12px; padding-left: 12px; }
  [dir="rtl"] .body-container .home-page-outer .row.main-menu .col-md-3 { padding-right: 0; }
}

@media (max-width:767px) {
  .body-container .main-menu .col-md-3 { width: 300px; max-width: 100%; margin: 15px auto 0 auto; padding-left: 15px; padding-right: 15px; }
  .body-container .main-menu .col-md-9 { width: 100%; padding-right: 15px; padding-left: 15px; }
  .body-container .main-menu .navbar #category-navbar-nav .nav-menu.navbar-nav { height: 40px; }
  .body-container .main-menu .search-news input.form-control { height: 40px; }
  .body-container .main-menu .search-news button.search-btn { height: 40px; }
  .row.row-two .col-md-9 .row.split-row .col-md-6.block-col1 { width: 100%; margin-bottom: 15px; padding-left: 15px; padding-right: 15px; }
  .row.row-two .col-md-9 .row.split-row.row-one .col-md-6.block-col2 { width: 100%; padding-left: 15px; padding-right: 15px; }
  .footer-wrap .row.copyright p { font-size: 11px; }
  .row.row-two .col-md-9 { width: 100%; margin-bottom: 20px; }
  .row.row-two .col-md-3 { width: 100%; }
  .head-outer .head-wrap .container .head-nav nav.bg-body-tertiary.navbar .navbar-brand { padding-left: 0; width: 150px; }
  .login-ul.navbar-nav { padding-right: 0; }
  .head-outer .search-form.top-serch { width: 100%; padding-left: 5px; padding-right: 5px; }
  .search-form input.form-control { width: 70%; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 { width: 100%; }
  .body-container .brand-wrp { margin-bottom: 20px!important; }
  .body-container .main-menu { padding-top: 0!important; }
  .row.empty-search-row { margin-top: 15px; }
  [dir=rtl] .row.row-two .col-md-9 .row.split-row.row-one .col-md-6.block-col2 { padding-left: 15px; padding-right: 15px; width: 100%; }
  [dir="rtl"] .row.row-two .col-md-9 .row.split-row .col-md-6.block-col1 { padding-right: 15px; padding-left: 15px; }
  .search-form button.search-btn { width: 10%; padding: 0; }
  [dir="rtl"] .row.row-two .col-md-9 .row.split-row .col-md-6.block-col1, [dir="rtl"] .row.row-two .col-md-9 .row.split-row .col-md-6.block-col2 { padding-right: 12px; padding-left: 12px; }
  [dir=rtl] .row.row-two .col-md-9 .row.split-row.row-one .col-md-6.block-col2 { padding-left: 12px; padding-right: 12px; width: 100%; }
  [dir="rtl"] .home-page-outer .row.row-two .col-md-3 { padding-right: 12px; padding-left: 12px; }
  [dir="rtl"] .home-page-outer .row.row-two .row .col-md-6.news-col-wrap.news-col-slider { padding-right: 12px; padding-left: 12px; }
  .home-page-outer .row.row-two .col-md-9 .col-md-6.news-col-wrap.news-col-wrap { padding-left: 12px; padding-right: 12px; }
  [dir="rtl"] .body-container .home-page-outer .row.main-menu .col-md-3 { padding-right: 15px; }
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right h2 { font-size: 16px; }
  .row.row-two .col-md-9 .row.split-row.row-three.search-row .col-md-6.block-col2 { width: 50%; }
  [dir="rtl"] .body-container .home-page-outer .row.row-two .col-md-9 .row.split-row.row-three.search-row { margin-left: -15px; margin-right: -15px; }
  [dir="rtl"] .body-container .home-page-outer .row.row-two .col-md-9 .row.split-row.row-three.search-row .col-md-6.block-col2 { width: 50%; }
}
@media (max-width:480px) {
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right .news-list-top .list-top-rgt { font-size: 10px; max-width: 50%; }
  .row-one .block-col2 .news-list .news-list-block .news-list-top .list-top-rgt { font-size: 9px; max-width: 50%; }
  .row.row-two .split-row.row-one .block-col2 .latest-wrap { width: 100%; margin-bottom: 15px; }
  .row.row-two .split-row.row-one .block-col2 .news-list { width: 100%; }
  .row.row-two .split-row.row-one .block-col2 .latest-wrap a { margin-bottom: 15px; }
  [dir="rtl"] .row.row-two .col-md-9 .row.split-row .col-md-6.block-col1, [dir="rtl"] .row.row-two .col-md-9 .row.split-row .col-md-6.block-col2 { padding-right: 12px; padding-left: 12px; }
  [dir=rtl] .row.row-two .col-md-9 .row.split-row.row-one .col-md-6.block-col2 { padding-left: 12px; padding-right: 12px; width: 100%; }
  [dir="rtl"] .home-page-outer .row.row-two .col-md-3 { padding-right: 12px; padding-left: 12px; }
  [dir="rtl"] .home-page-outer .row.row-two .row .col-md-6.news-col-wrap.news-col-slider { padding-right: 12px; padding-left: 12px; }
  [dir="rtl"] .search-form label.custom-select select.form-select { padding: 0 10px 0 20px; }
  [dir="rtl"] .body-container .home-page-outer .row.row-two .col-md-9 .row.split-row.row-three.search-row .col-md-6.block-col2 { width: 100%; }
}

@media (max-width:414px) {
  .body-container .main-menu .col-md-3 { width: 100%; max-width: 100%; }
  .not-available-text { font-size: 14px; }
}
@media (max-width:400px) {
  .row.row-two .split-row.row-one .block-col2 .latest-wrap { width: 100%; margin-bottom: 15px; }
  .row.row-two .split-row.row-one .block-col2 .news-list { width: 100%; }
  .footer-wrap .row.copyright p { font-size: 12px; }
  .row.row-two .col-md-9 .row.split-row.row-three .col-md-6.block-col1 .news-wrap-block .latest-block.card .card-body .latest-block { width: 100%; }
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-left { width: 100%; height: 160px; }
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right { width: 100%; margin-top: 15px; }
  .row.row-two .col-md-9 .row .col-md-6:nth-child(2n + 2) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img,   .row.row-two .col-md-9 .row .col-md-6:nth-child(4) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img, .row.row-two .col-md-9 .row .col-md-6:nth-child(6) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-img { width: 100%; height: 160px; }
  .row.row-two .col-md-9 .row .col-md-6:nth-child(2n + 2) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt,   .row.row-two .col-md-9 .row .col-md-6:nth-child(4) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt, .row.row-two .col-md-9 .row .col-md-6:nth-child(6) .row.split-row.row-three .block-col1.col-md-12 .news-wrap-block .latest-block.card .card-body .latest-block .news-list-cnt { width: 100%; margin-top: 15px; }
  .row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right { width: 100%; margin-top: 15px; }
  .row.row-two .split-row.row-one .block-col2 .latest-wrap { width: 100%; }
  .row-one .block-col2 .latest-wrap .latest-block .latest-img { height: 160px; }
  .row.row-two .split-row.row-one .block-col2 .news-list { width: 100%; max-height: inherit; }
}
@media (max-width:360px) {
  .head-outer .head-wrap .container .head-nav nav.bg-body-tertiary.navbar .navbar-brand { width: 125px; }
}
