.product-wrapp {
    width: 100%;
    display: block;
    float: left;
    padding: 40px 0 60px;
}

.product-wrapp .productContainer {
    text-align: center;
}

.product-wrapp h3 {
    color: #000000;
    font-size: 36px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    margin: 0 0 14px;
}

.product-wrapp p {
    color: #939393;
    font-size: 20px;
    line-height: 1.45;
    letter-spacing: normal;
    font-weight: normal;
    margin: 0 auto 24px;
    max-width: 680px;
    float: none;
}

.product-wrapp .productRow {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 0 0;
}

.product-wrapp .productRow .productCol {
    width: 22%;
    float: left;
    display: inline-block;
    background: #ffffff;
    border: 1px solid #D2D2D2;
    border-radius: 18px;
    overflow: hidden;
    margin: 0 4% 40px 0;
}

.product-wrapp .productRow .productCol .productCol1 {
    width: 100%;
    display: block;
    float: left;
    padding: 30px 25px 15px;
}

.product-wrapp .productRow .productCol .prd-row1 {
    width: 59.5%;
    float: left;
    text-align: left;
    display: inline-block;
}

.product-wrapp .productRow .productCol:nth-child(4n) {
    margin-right: 0;
}

.product-wrapp .productRow h2 {
    font-size: 20px;
    color: #000000;
    font-weight: 600;
}

.product-wrapp .productRow .productCol .prd-row2 {
    width: 40%;
    float: right;
    display: inline-block;
}

.product-wrapp .productRow .productCol .productCol2 {
    width: 100%;
    display: block;
    float: left;
}

.product-wrapp img {
    width: 100%;
    display: block;
}

@media (max-width:1446px) {
    .product-card {
        width: 30.33% !important;
        margin-left: 3%;
    }

    .product-button.btn2 {
        padding: 10px 15px;
    }
}

@media (max-width:1200px) {
    .product-card {
        width: 15.25rem !important;
        margin-left: 0;
    }
    
    .product-card:nth-child(2n) {
        margin-left: 1.5rem;
    }
}

@media screen and (max-width: 576px) {
    .product-card {
        width: 100% !important;
    }
    
    .product-card img {
        width: 100%;
    }
    
    .product-card:nth-child(2n) {
        margin-left: 0;
    }
}

@media screen and (max-width: 900px) {

    .product-card {
        width: 31.33% !important;
        margin-right: 3% !important;
    }
    
    .product-card:nth-child(3n) {
        margin-right: 0;
    }
    
    .product-card:nth-child(3n) {
        margin-right: 0 !important;
    }

    .product-button.btn1, .product-button.btn2 {
        font-size: 11px;
    }

}

@media screen and (max-width: 768px) {

    .product-button.btn1, .product-button.btn2 {
        font-size: 11px;
        padding: 8px 10px;
    }
}

@media screen and (max-width: 700px) {

    .product-card {
        width: 48% !important;
        margin-right: 4% !important;
    }
    
    body.detail-classhide .body-container .container .lower > div .data {
        display: flex;
        flex-wrap: wrap;
    }
    
    .product-card:nth-child(2n) {
        margin-right: 0 !important;
    }
    
    .product-card:nth-child(3n) {
        margin-right: auto !important;
    }
}

@media screen and (max-width: 576px) {
    .store-wrap .store-item-detail .store-item-avathar {
        width: 100%;
        margin: 0;
    }
    
    .store-wrap .store-item-detail .store-item-info {
        width: 100%;
    }
    
    .store-wrap .store-item-detail .store-item-main img {
        width: auto;
        max-height: 400px;
        margin: 0 auto 0;
    }
    
    .store-wrap .store-item-detail .store-item-main {
        margin: 0 0 15px;
    }
    
    .store-wrap .store-item-detail .store-item-info .store-item-title .title {
        font-size: 24px;
    }
}

@media screen and (max-width: 480px) {
    .product-card {
        width: 100% !important;
        margin-right: 0 !important;
    }
}