

.search-field .search-field-dropdown .form-check-input {
    /* display: none; */
    display: inline;
}
.search-field .search-field-dropdown .form-check:has(.form-check-input:checked) {
    background-color: #316BFF;
    color: #fff;
}

.search-field .search-field-dropdown .form-check:has(.form-check-input:checked) .store-wrap .card .input-field .form-check label.form-check-label{color: #fff;}

.slidecontainer {
    width: 100%;
}

.slider-container {
    position: relative;
    height: 25px;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    outline: none;
    opacity: 0.7;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb,
.slider::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #3686f7 ;
    cursor: pointer;
}

.slider::-webkit-slider-runnable-track,
.slider::-moz-range-track {
    height: 15px;
}

.slider-track {
    position: absolute;
    top: 50%;
    height: 4px;
    background-color: #3686f7 ;
    z-index: 1;
    transform: translateY(-50%);
}

.store-wrap .card-body input[type = range] {background: transparent;position: absolute;top: 50%;transform: translateY(-50%);opacity: 1;z-index: 9;}

.store-wrap .card-body .slider-container {
    width: 100%;
    background: #ddd;
    height: 9px;
    margin: 10px 0 20px;
    position: relative;
}

.store-wrap .card-body .slider-container .slider-track {
    background-color: #316BFF;
    height: 3px;
}

.search-field-input.input-group.has-validation::after {
    position: absolute;
    content: "";
    background: url(../../images/min_down-line.svg);
    width: 20px;
    height: 20px;
    border-radius: 5px;
    display: inline-block;
    color: #000;
    font-size: 14px;
    z-index: 99;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
.search-field-input.input-group.has-validation:hover {
    cursor: pointer;
}


.slidecontainer p {
    color: #9D9D9D;
}
.search-field-dropdown.input-group.has-validation .form-check:hover {
    background: #316BFF;
    color: #fff;
}

