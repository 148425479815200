@keyframes marquee {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}
.row-one .block-col1 .news-block .card-body .trend-news .marquee { width: calc(100% - 135px); margin-left: 20px; height: 25px; overflow: hidden; position: relative; }
.row-one .block-col1 .news-block .card-body .trend-news .marquee .marquee-wrap {    display: flex;
  width: max-content;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 240s linear infinite;
  flex-direction: row-reverse; }
/* @keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
} */
.row-one .block-col1 .news-block .card-body .trend-news .marquee .trend-txt { font-size: 15px; font-weight: 400; color: #000; text-decoration: none!important; float: left; /*width: 50%;*/ padding: 0 10px 0 0; margin: 0 10px 0 0; position: relative;}
.row-one .block-col1 .news-block .card-body .trend-news .marquee .trend-txt:after {content: ""; position: absolute; right: 0; top: 39%; transform: translateY(-50%); background: #000; width: 1px; height: 50%;}

.row-one .block-col1 .news-block .card-body .trend-news .hd { background: #ff302f; color: #fff; padding: 0 10px; line-height: 20px; font-size: 11px; border-radius: 4px; height: 20px; float: left; margin-bottom: 0; }
.row-one .block-col1 .news-block .card-body .trend-news { float: left; width: 100%; display: flex; flex-wrap: wrap; align-content: center; padding: 15px; }
.row-one .block-col1 .news-block .card-body .trend-news .marquee .trend-txt .trending { padding: 0!important; height: auto; }
.row-one .block-col1 .news-block { border-radius: 8px 8px 0 0!important; }

[dir="rtl"]  .row-one .block-col1 .news-block .card-body .trend-news .marquee {
  margin-left: 0;
  margin-right: 20px;
}
[dir="rtl"]  .row-one .block-col1 .news-block .card-body .trend-news .marquee .marquee-wrap {
  flex-direction: row;
}