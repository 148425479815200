.news-modal.modal .modal-dialog { position: absolute!important; left: 0!important; top: 50%!important; right: 0!important; margin: 0 auto!important; transform: translateY(-50%)!important; width: 500px; max-width: 90%; }
.news-modal .modal-dialog .modal-content .modal-header { position: relative; flex-direction: column; text-align: center; border: none; }

.news-modal .modal-dialog .modal-content .modal-header .modal-title.h4 { width: 100%; float: left; text-align: center; margin-bottom: 10px; }

.news-modal .modal-dialog .modal-content .modal-header p { float: left; width: 100%; margin: 0; text-align: center; color: #000; font-size: 15px; }

.news-modal .modal-dialog .modal-content .modal-header button.btn-close { position: absolute; right: 15px; top: 15px; }

.news-modal .modal-dialog .modal-content .modal-body { padding: 0 30px 15px 30px; border: none; }

.news-modal .modal-dialog .modal-content .modal-body .card { border: none; box-shadow: none; }

.news-modal .modal-dialog .modal-content .modal-body .card .card-body { padding: 0; }
.news-modal .modal-dialog .modal-content .modal-body .card .card-body form { float: left; width: 100%; }

.news-modal .modal-dialog .modal-content .modal-body .card .card-body form .input-field { float: left; width: 100%; }

.news-modal .modal-dialog .modal-content .modal-body .card .card-body form .input-field label.form-label { display: none; }

.news-modal .modal-dialog .modal-content .modal-body .card .card-body form .input-field input.form-control { height: 50px; border-radius: 5px; border: 1px solid #ccc; font-size: 15px; }

.news-modal .modal-dialog .modal-content .modal-body .card .card-body form button.btn.btn-primary { height: 50px; border-radius: 5px; border: 1px solid #3686f7; font-size: 16px; color: #fff; background: #3686f7; padding: 0 30px; font-weight: 500; width: 100%; }

.news-modal .modal-dialog .modal-content .modal-body .card .card-body form button.btn.btn-primary:hover { border: 1px solid #000; color: #fff; background: #000; }

.news-modal .modal-dialog .modal-content .modal-footer { border: none; text-align: center; padding: 0 15px 15px 15px; }

.news-modal .modal-dialog .modal-content .modal-footer p { text-align: center; width: 100%; color: #000; font-size: 15px; }
