.Billing {
  width: 28rem;
  height: auto;
  background-color: white;
  margin-top: 2rem;
  margin-left: -1rem;
  border-radius: 15px;
  padding-bottom: 4rem;
}

.Billing h2 {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 20px;
  margin-left: 2rem;
  padding-top: 2rem;
}
.Billing input {
  display: block;
  margin-left: 3rem;
  width: 22rem;
  height: 2rem;
  padding: 5px;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid grey;
  outline: none;
}
.Address input {
  display: block;
  margin-left: 3rem;
  width: 22rem;
  height: 5rem;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid grey;
  outline: none;
}
.checkbox {
  display: flex;
  margin-top: 1rem;
}
.checkbox label {
  padding-top: 1rem;
  margin-left: 1rem;
  font-family: "Poppins", serif;
  font-weight: 400;
  color: grey;
}
.checkbox input {
  width: 20px;
  height: 20px;
}
