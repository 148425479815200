.Detail {
  display: flex;
}

.product-page {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 800px;
}

.breadcrumb {
  margin-top: 1rem;
  margin-left: 2rem;
  color: #555;
}

.content {
  display: flex;
  gap: 20px;
  margin-left: 5rem;
  margin-top: 2rem;
}

.main-image {
  width: 28rem;
  border-radius: 8px;
}

.thumbnails {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.thumbnail {
  width: 30%;
  border-radius: 4px;
  cursor: pointer;
}

.details-section {
  text-align: left;
}

.details-section h1 {
  width: 28rem;
  font-size: 25px;
  margin-bottom: 10px;
  font-family: "Poppins", serif;
  font-weight: 600;
}
.details-section p {
  text-align: left;
  margin: 0px;
  font-size: 20px;
  margin-top: 1rem;
}

.price {
  font-family: "Poppins", serif;
  font-weight: 500;
  margin-top: 1rem;
}

.price span {
  font-size: 18px;
  color: #555;
  text-decoration: line-through;
  margin-left: 0.5rem;
}

.book-details0 span {
  margin-left: 5rem;
}
.book-details1 span {
  margin-left: 6.6rem;
}
.book-details2 span {
  margin-left: 7.3rem;
}
.book-details3 span {
  margin-left: 8.8rem;
}
.book-details4 span {
  margin-left: 7.2rem;
}
.counter {
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
}
.counter button {
  width: 3rem;
  height: 3rem;
  border: none;
  border-radius: 5px;
}

.cart-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.add-to-cart {
  height: 3rem;
  width: 10rem;
  font-size: 16px;
}
.cart {
  margin-top: 2rem;
}
.cart button.add-to-cart img {
  padding-left: 3px;
  margin-bottom: -4px;
}
.quantity-button {
  padding: 5px 10px;
  font-size: 18px;
  border: 1px solid #ccc;
  background: white;
  cursor: pointer;
}

.add-to-cart {
  background-color: #1a73e8;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  border: 1px solid #3686f7;

}
.add-to-cart:hover{
  color: #3686f7;
    background-color: transparent;
    border: 1px solid #3686f7;
}



.additional-info {
  margin-top: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.additional-info hr {
  margin-top: 1rem;
}

.additional-info h3 {
  margin-top: 0rem;
  font-size: 20px;
  font-weight: 600;
}

.additional-info h3 span {
  float: right;
  padding-right: 0.25rem;
  font-family: "Poppins", serif;
  font-weight: 400;
  cursor: pointer;
}
.other {
  font-family: "Poppins", serif;
  font-size: 20px;
  font-weight: 400;
  margin-top: 2rem;
}

.additional-info + .store-item-content {
  padding-bottom: 30px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  position: relative;
  top: -1px;
  border-top: 1px solid #f3f3f8;
}
