.search-form { display: flex; width: 100%; margin: 0; padding: 0; align-items: center; justify-content: center; }

.search-form label.custom-select select.form-control { height: 50px; background: #3686f7; display: inline-block; width: 100%; border-radius: 5px 0 0 5px; padding: 0 20px; color: #fff; position: relative; border: 1px solid #3686f7; text-align: center; }

.search-form label.custom-select { position: relative; display: inline-block; width: 20%; border-radius: 5px 0 0 5px; margin: 0; }

.search-form label.custom-select select.form-select { box-shadow: none!important; height: 50px; background: #316BFF; display: inline-block; width: 100%; padding: 0 20px; color: #fff; border: 1px solid #316BFF; border-radius: 5px 0 0 5px; font-size: 16px; }

.search-form .custom-select:after { content: "▼"; position: absolute; top: 11px; right: 10px; font-size: 80%; padding: 5px; color: #fff; pointer-events: none; }

.search-form input.form-control { height: 50px; background: #fff; display: inline-block; width: 95%; border-radius: 0; padding: 0 20px; color: #222; border: 1px solid #DFDFDF; font-size: 15px; }

.search-form input.form-control::placeholder { font-size: 15px; }

.search-form button.search-btn { height: 50px; background: url(../../images/search-icon.svg) #316BFF no-repeat center center; background-size: 24px; display: inline-block; width: 5%; border-radius: 0 5px 5px 0; padding: 0 20px; color: #fff; position: relative; border: 1px solid #316BFF;  }

.search-form button.search-btn:hover { background: url(../../images/search-icon.svg) #222 no-repeat center center; background-size: 24px; border: 1px solid #222; }

.search-form input.form-control:focus { box-shadow: none; }

[dir="rtl"] .search-form button.search-btn { border-radius: 5px 0 0 5px; }

[dir="rtl"] .search-form label.custom-select select.form-select { border-radius: 0 5px 5px 0; }

[dir="rtl"] .search-form .custom-select::after { right: auto; left: 10px; }

[dir="rtl"] .head-wrap .head-nav .login-ul.navbar-nav a.nav-link:last-child::before { display: none; }

[dir="rtl"] .head-wrap .head-nav .login-ul.navbar-nav a.nav-link:first-child::before { position: absolute; left: -3px; width: 1px; height: 22px; background: #3686f7; content: ""; top: 2px; }

[dir="rtl"] .head-wrap .head-nav a { margin-right: 0; }
[dir="rtl"]  .stiky-header .head-wrap .head-nav nav.bg-body-tertiary.navbar.navbar-expand-lg #basic-navbar-nav { margin-left: auto; margin-right: 35%; }

[dir="rtl"] .head-outer.stiky-header .search-wrap { margin-left: 0; margin-right: 32%; float: right; }

[dir="rtl"] .head-outer.stiky-header .search-wrap .search-form input.form-control { border-radius: 0 5px 5px 0; text-align: right; }
[dir="rtl"] .head-outer.stiky-header .search-wrap .search-form input.form-control::placeholder { text-align: right; }
