.row.row-two .col-md-9 .row.split-row.row-three.search-row .col-md-6.block-col2 { width: 49%; margin-bottom: 15px; }

.row.row-two .col-md-9 .row.split-row.row-three.search-row .col-md-6.block-col2 .latest-wrap.latest-wrap-row { height: auto;    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px; }

.row.row-two .col-md-9 .row.split-row.row-three.search-row .col-md-6.block-col2 .latest-wrap.latest-wrap-row .news-slider2-wrap { padding-top: 0; }





@media (max-width:480px) {
.row.row-two .col-md-9 .row.split-row.row-three.search-row .col-md-6.block-col2 {
    margin-bottom: 15px;
    width: 100%;
  }
}

@media (max-width:400px) {
.row.row-two .block-col2 .latest-wrap .news-slider2-wrap .news-slider2 .latest-block.card .card-body .news-list-right h2 {
    font-size: 16px;
  }
}