.account-wrap{
    width: 100%;
}
.order-item {
    width:100%;
    display:flex;
}
.order-item-avathar {
    width:100px;
    margin:0px 10px
}
.order-item-title {
    width:100%
}
.order-item-action {
    width:200px;
    margin:0px 10px
}
.react-datepicker-wrapper{
    width: 100%;
}
.avathar{
    width:200px;
}

.account-wrap .row {
    justify-content: center;
}
.account-wrap .card .card-body {
    padding: 45px 30px 45px;
}
.account-wrap .col-md-9 .card .card-body {
    background: #f3f3f8;
    border-radius: 5px;
    padding: 45px 6%;
}
.account-wrap .card .card-body .form-links {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    margin: 0 0 0;
    position: absolute;
    right: 0;
    top: 5px;
}
.account-wrap .card .card-body .tab-pane {
    position: relative;
}
.account-wrap .card .card-body .profileForm h3 {
    margin: 0 0 22px;
}
.account-wrap .card .card-body .form-links a {
    padding: 0 0 0 8px;
}
.account-wrap .card .card-body .profileForm label {
    display: block;
    font-size: 16px;
    margin: 0 0 6px;
}
.account-wrap .card .card-body .profileForm  input {
    background: #fff;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    color: #000;
    display: block;
    float: left;
    font-size: 15px;
    height: 48px;
    letter-spacing: normal;
    line-height: normal;
    margin: 0 0 0;
    outline: none;
    padding: 0 9px;
    width: 100%;
}

.account-wrap .card .card-body h4 {
    margin: 0 0 20px;
}

/* .account-wrap .card .card-body #account-tabs-tabpane-order {} */

.account-wrap .card .card-body #account-tabs-tabpane-order .card-body {
    padding: 12px 23px;
}

.account-wrap .card .card-body #account-tabs-tabpane-order .card-body .order-item-action {
    display: inline-block;
    width: 22.5%;
    margin: auto 0 0;
    text-align: center;
}

.account-wrap .card .card-body #account-tabs-tabpane-order .card-body .order-item-action a {
    width: 100%;
    display: block;
}

.account-wrap .card .card-body #account-tabs-tabpane-order .card-body .order-item {
    flex-wrap: wrap;
}

.account-wrap .card .card-body #account-tabs-tabpane-order .card-body .order-item-title {
    width: 58%;
    margin: 0 1.5% 0 0;
}

.account-wrap .card .card-body #account-tabs-tabpane-order .card {
    margin: 0 0 11px;
}

.account-wrap .card .card-body .profileForm h6 {
    font-size: 18px;
    font-weight: 500;
    margin: 8px 0 16px;
}

.account-wrap .card .card-body .profileForm .form-group .form-control {
    min-height: 48px;
    margin: 0 0 1rem;
}

.account-wrap .card .card-body .avathar {
    width: 100%;
    display: block;
    margin: 0 0 18px;
}

.account-wrap .card .card-body .col-md-12 {
    padding: 0;
}

.account-wrap .card .card-body .col-md-12 .gallery {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0 0;
}

.account-wrap .card .card-body .col-md-12 .gallery .gallery-item {
    width: 49%;
    margin: 0 2% 0 0;
}

.account-wrap .card .card-body .col-md-12 .gallery .gallery-item:nth-child(2n) {
    margin-right: 0;
}

.account-wrap .card .card-body .col-md-12 .gallery img {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
}

.account-wrap .card .card-body .row {
    width: 100%;
    margin: 0 0 0;
    justify-content: space-between;
}

.account-wrap .card .card-body .profileForm .col-md-6 {
    padding: 0;
    width: 49%;
}

.account-wrap .card .card-body .col-md-12 {
    padding: 0;
}

.account-wrap .card .card-body .profileForm .form-action .btn-secondary {
    margin: 0 6px 0 0;
}

@media screen and (max-width:1024px) {
    .account-wrap .card .card-body {
        padding: 35px 6% 30px;
    }
    .account-wrap .col-md-9 .card .card-body {
        padding: 40px 4% 38px;
    }
    .account-wrap .card .card-body .profileForm h3 {
        font-size: 25px;
    }
    .account-wrap .card .card-body .form-links a {
        font-size: 14px;
    }
    .account-wrap .card .card-body .profileForm label {
        font-size: 14px;
    }
    .account-wrap .card .card-body .profileForm input {
        height: 44px;
    }
}

@media screen and (max-width: 767px) {
    .account-wrap .card .card-body .profileForm h3 {
        font-size: 23px;
    }
    .account-wrap .col-md-3 {
        margin: 0 auto 1%;
    }
    .account-wrap .card .card-body .nav-item {
        text-align: center;
    }
    .account-wrap .profileForm {
        text-align: center;
    }
    .account-wrap .card .card-body .profileForm input {
        text-align: center;
    }
    .account-wrap .card .card-body .form-links {
        width: 100%;
        position: relative;
        top: inherit;
        justify-content: center;
        margin: 0 0 18px;
    }
}

@media screen and (max-width: 576px) {
    .account-wrap .card .card-body .profileForm h3 {
        font-size: 23px;
    }
    .account-wrap .col-md-9 .card .card-body {
        padding: 40px 5% 38px;
    }
    .account-wrap .card .card-body .form-links a {
        font-size: 14px;
        padding: 0 5px 0;
    }
    .account-wrap .card .card-body {
        padding: 35px 5% 30px;
    }
    .account-wrap .card .card-body .form-links {
        position: relative;
        top: 0;
        justify-content: center;
        width: 100%;
        margin: 0 0 28px;
    }

    .account-wrap .card .card-body .profileForm .col-md-6 {
        width: 100%;
    }
    
}