.card-block.card { height: 355px; float: left; width: 100%; border-radius: 5px; overflow: hidden; margin-bottom: 15px; }
.card-block.card a { display: inline-block; width: 100%; height: 100%; }
.card-block.card a img { width: 100%; height: 100%; }

@media (max-width:767px) {
.card-block.card {
    height: auto;
}

.card-block.card a img {
    height: auto;
}
}