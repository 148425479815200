ul.lawer-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    list-style: none;
    padding-left: 0;
}

.practice-area{
    background-color: #3686f7;
    border: 1px solid #3686f7;
    width: 49%;
    font-size: 13px;
    text-decoration: none;
    margin-bottom: 0;
    text-align: center;
    transition: all 0.5s ease-in-out;
    color: #fff;
    padding: 10px 14px;
    margin: 20px 0;
    border-radius: 4px;
}
.practice-area:hover{
    border: 1px solid #3686f7;
    background-color: transparent;
    transition: all 0.6s ease-in-out; 
    color: #3686f7;
}

.lawer-ul-sec {
    margin-top: 40px;
}

.lawer-1-sec {
    margin-bottom: 40px;
}
.lawer-2-sec {
    margin-block: 40px;
}

.lawer-ul-sec .l-te a {
    text-decoration: none;
    color: #316BFF;
}
.lawer-ul-sec .l-te a:hover {
    color: #606060;
}

.l-te h2 {
    margin-bottom: 20px;
    font-size: 21px;
    font-weight: 700;
}
.lawer-sub-sec h4 {
    margin: 45px 0 20px 0;
    font-size: 25px;
    font-weight: bold;
}


.lawer-sub-sec h5 {
    margin: 0 0 20px 0;
    font-size: 20px;
}
.lawer-sub-sec h6 {
    margin-bottom: 10px;
    font-weight: bold;
}

.lawyerclass .body-container .container{
    background: #fff;
    padding: 20px;
    border-radius: 13px;
}

.all-lawer-sec{
    margin-bottom: 50px;
}
.pratice-area-lawer{
    display: flex;
    justify-content: space-between;
}
.pratice-area-lawer > div{
    width: 24%;
    border: 1px solid #606060;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pratice-area-lawer p {
    margin-bottom: 0;
    padding-block: 14px;
}
.pratice-area-lawer > div a{cursor: pointer;}
.pratice-area-lawer > div a:hover
{
    color: #316BFF !important;
}