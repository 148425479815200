@import url("https://fonts.googleapis.com/css2?family=DynaPuff:wght@400..700&family=Oswald:wght@200..700&family=Pacifico&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.main {
  width: 18rem;
  height: 100%;
  background-color: rgb(255, 255, 255);
  font-family: "Poppins", serif;
  font-weight: 500;
}

.Filters {
  padding-top: 4rem;
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 18px;
}

.main h3 {
  padding-top: 2rem;
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  margin-left: 2rem;
}
.main input {
  height: 2rem;
  width: 14rem;
  margin-left: 2rem;
  margin-top: 0.2rem;
  border: 0.5px solid rgba(128, 128, 128, 0.514);
  border-radius: 3px;
  padding: 5px;
}
.main select {
  width: 14rem;
  height: 2rem;
  margin-left: 2rem;
  border: 0.5px solid rgba(128, 128, 128, 0.514);
  border-radius: 3px;
  padding: 5px;
}
