.store-wrap{
    width: 100%;
}
.store-items {
    width:100%;
    display:flex;
    flex-wrap:wrap;
    margin-top: 22px;

}
.store-items .store-item{
    width: 96%;
    margin: 0 0 40px 4%;
    padding: 40px 35px;
    box-shadow: 0 0 4px 1px #ddd;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    background: #faf9fc;
}
.store-items .store-item>a{
    width: 100%;
    text-decoration:none;
    height: 100%;
    display: block;
}
.store-items .store-item .store-item-body{
    width:100%;
    /* border:1px solid #afafaf; */
    /* border-radius:1px; */
    overflow:hidden;
    padding: 0 0 0;
    display: flex;
    flex-wrap: wrap;
    /* height: 100%; */
}
.store-items .store-item .store-item-body .store-avathar{
    width: 18%;
    margin: 0 4% 0 0;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    /* height: 60%; */
}
.store-items .store-item .store-item-body .store-avathar img {
    max-width: 100%;
    max-height: 165px;
    object-fit: cover;
    width: 100%;
    height: 9.20vw;
}
.store-items .store-item .store-item-body .store-title{
    width: 100%;
    color: #222222;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.45;
    margin: 0 0 6px;
    font-size: 20px;
    text-transform: capitalize;
}
.store-items .store-item .store-item-body .store-sub-title{
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    color: #bfbfbf;
    letter-spacing: normal;
    line-height: 1.45;
    margin: 0 0 6px;
}

/* .store-items .store-item .store-item-body > div {
    padding: 0 7.5% 0;
} */

.store-items .store-item .store-item-body .store-detail {
    width: 56.5%;
    margin: 0 3% 0 0;
}

.store-wrap .col-md-9 .card .card-body {
    padding: 8px 30px 20px;
}

.store-items .store-item:nth-child(4n) {
    margin-right: 0;
}

.store-wrap .card .mobile-fade-btn {
    display: none;
}

.store-items .store-item .store-item-body .store-detail .store-sub {
    margin: 0 0 18px;
}

.store-items .store-item .store-item-body .store-detail .store-actions .btn:nth-child(2) {
    margin: 0 0 0 5px;
}

@media (max-width:1300px) {
    .store-items {
        justify-content: center;
    }
}

@media screen and (max-width:991px){
    .store-items .store-item {
        margin: 0 0 30px 4%;
        /* width: 23.5%; */
        padding: 25px 22px;
    }
    .store-items .store-item .store-item-body > div {
        padding: 0 6% 0;
    }
    .store-items .store-item .store-item-body .store-title span {font-size: 14px;}  
    .store-items .store-item .store-item-body .store-sub-title {
        font-size: 11px;
    }   
    
    .store-items .store-item .store-item-body .store-avathar img {
        height: 10vw;
    }
}

@media screen and (max-width:768px){
    .store-items .store-item .store-item-body .store-avathar {
        height: 50%;
        margin: 0 0 0;
    }
    .store-items .store-item .store-item-body > div {
        padding: 0 6% 0;
    }
    .store-items .store-item .store-item-body .store-title span {font-size: 14px;}  
    .store-items .store-item .store-item-body .store-sub-title {
        font-size: 11px;
    } 
    .store-items .store-item .store-item-body {
        padding: 0 0 0;
    }

    .store-items .store-item .store-item-body .store-avathar img {
        height: 10vw;
    }
}

@media screen and (max-width:767px){

    .store-wrap .mobile-fade {
        position: relative;
    }

    .store-wrap .card .card-header {
        border: none;
    }
    
    .store-wrap .card .mobile-fade-btn {
        display: none;
        width: 100%;
        position: absolute;
        left: 0;
        height: 100%;
        top: 0;
        border: none;
        background: none;
        -webkit-appearance: none;
        outline: none;
        box-shadow: none;
    }

    .store-wrap .card .mobile-fade-btn:after {
        content: "";
        width: 10px;
        height: 8px;
        right: 12px;
        background: url(../images/dropdown-arrow-black.svg) #ffffff no-repeat center center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    
    .store-wrap .card .mobile-fade-btn {
        display: block;
    }
    
    .store-wrap .mobile-fade .card-body {
        width: 100%;
        position: absolute;
        z-index: 9;
        background: #ffffff;
        border: 1px solid #dadada;
        top: 41px;
        display: none;
        box-shadow: 7px 5px 7px #00000017;
        max-height: 300px;
        overflow-y: auto;
    }
    
    .store-wrap .mobile-fade.active .card-body {
        display: block;
    }

    .store-wrap .mobile-fade.active .card-body .col-md-12:first-child {
        border-top: 0;
        padding-top: 0;
    }
    .store-wrap .mobile-fade.active .card-body .col-md-12 {
        border-top: 1px solid #dadada;
        padding-top: 18px;
    }

    .store-wrap .col-md-9 .row {
        margin: 1rem 0 0;
        width: 100%;
    }

    .store-items .store-item .store-item-body .store-avathar img {
        height: 18vw;
    }

    .store-items .store-item {
        padding: 15px 3%;
        margin: 0 0 20px 4%;
    }
}



/* new store css */

.new-store-item {
    background: #fff;
    border-radius: 11px;
}


.new-store-item .book-details{
    padding: 25px 20px;
}


.new-store-item .store-item-body .store-avathar img {
    max-width: 100%;
    height: 300px;
    object-fit: cover;
    width: 100%;
    margin-bottom: 15px;
}

.new-store-item a{
    text-decoration: none;
}

.new-store-item .store-title h5 {
    text-decoration: none;
    color: #000;
    font-size: 13px;
    line-height: 19px;
    min-height: 45px;
    font-weight: 600;
}

.new-store-item .store-title.author h6{
    text-decoration: none;
    color: #606060;
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
}

.new-store-item .store-title p{
    color: #929292;
    font-size: 12px;
    line-height: 19px;
    font-weight: 300;
}
.new-store-item .store-title.price h6{
    text-decoration: none;
    color: #000;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
}

.cart-buttons .book-now-button{
        border: 1px solid #3666f7;
        background: #3666f7;
        border-radius: 5px;
        color: #fff;
        padding: 6px 15px;
        font-size: 12px;
        margin-right: 12px;
}

.cart-buttons .add-cart{
    background: none;
    color: #316BFF;
}

.cart-buttons .book-now{
    
}

@media screen and (max-width:576px){

    .store-items .store-item {
        width: 100%;
        margin: 0 0 15px;
    }
    
    .store-wrap .col-md-9 .row {
        padding: 0;
        margin: 0.5rem 0 0;
    }
}